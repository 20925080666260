@import 'styles/references/index.scss';

.xpay-recent-transactions {
  border-top: 1px $table-border solid;
  margin-top: 16px;
  width: 100%;
  max-width: 425px;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  row-gap: 15px;
  height: 50%;

  @include xpay-pre-lg {
    border-top: none;
    padding-bottom: 80px;
    max-width: unset;
  }

  .header {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 0;

    h3 {
      font-size: 18px;
      width: 100%;
      text-align: right;
      margin-right: 4px;
    }

    > img {
      display: none;
      @include xpay-pre-lg {
        display: block;
      }
    }

    .recent-transactions-button {
      justify-self: flex-end;
      width: auto;

      button {
        background: transparent;
        display: none;
        justify-content: center;
        align-items: center;
        width: max-content;
        height: 30px;
        column-gap: 4px;
        font-size: 12px;
        color: $info;
        border: 1px solid $table-border;
        @include xpay-pre-lg {
          display: flex;
          width: max-content;
        }
      }

      @include xpay-pre-lg {
        display: flex;
      }
    }
  }

  .transactions-list {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    @include xpay-size(row-gap, 25px, 16px);
  }
}
