@import 'src/styles/references/index';

.xpay-slider {
  position: relative;
  width: 100%;
  margin: 0 auto;
  height: 100%;

  .swiper {
    overflow-y: hidden;
    height: 100%;
    padding: 0px 32px;
    // padding-top: 32px !important;
    display: flex;
    justify-content: center;
    align-items: center;

    @include xpay-pre-xl {
      padding: 10px 10px 0;
    }

    @include xpay-pre-lg {
      padding: 0;
    }

    .swiper-wrapper {
      height: auto;
    }
  }
  .pre-navigation {
    background: rgba(80, 115, 251, 0.2);
    backdrop-filter: blur(30px);
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 10px;
    left: 16px;
    top: calc(50% - 20px);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;

    @include xpay-pre-lg {
      left: 0px;
    }
  }

  .next-navigation {
    background: rgba(80, 115, 251, 0.2);
    backdrop-filter: blur(30px);
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 10px;
    cursor: pointer;
    right: 16px;
    top: calc(50% - 20px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;

    @include xpay-pre-lg {
      right: 0px;
    }
  }

  .swiper-pagination-bullets {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    bottom: 1px;

    .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      background: $primary;
      opacity: 0.5;
      border-radius: 30px;
      display: flex;
      justify-content: center;
      align-items: center;

      &.swiper-pagination-bullet-active {
        width: 16px;
        height: 16px;
        opacity: 1;
        background: $primary;

        &::before {
          content: '';
          display: block;
          width: 8px;
          height: 8px;
          background: $white;
          border-radius: 30px;
        }
      }
    }
  }

  .swiper-slide {
    background: $primary;
    border-radius: 10px;
    height: 100%;
    max-height: 248px;

    .xpay-slide {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      margin: 0 auto;
      padding: 0 5%;
      min-height: 250px;

      @include xpay-pre-lg {
        min-height: 150px;
      }

      .right-side {
        text-align: right;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        width: 48%;
        padding: 16px;
        height: 80%;

        @include xpay-pre-xl {
          width: 48%;
        }

        @include xpay-pre-lg {
          width: 60%;
          padding: 20px 10px;
        }

        h2 {
          width: 100%;

          @include xpay-pre-lg {
            font-size: 14px;
          }
        }

        p {
          font-size: 14px;

          @include xpay-pre-lg {
            font-size: 12px;
          }
        }

        h2,
        p {
          text-align: right;
          margin-bottom: 8px;
          color: $white;
        }
        .xpay-button {
          margin: unset !important;
          width: 151px;
          height: 51px;
          margin-left: auto !important;

          button {
            background: $secondary;
            @include xpay-pre-lg {
              height: 29px;
              width: 80%;
            }
            span {
              font-size: 14px;
              font-weight: 500;
            }
          }
        }
      }
      img.shape {
        position: absolute;
        top: -4%;
        left: 2.5%;
        height: 108%;

        @include xpay-pre-lg {
          top: 10px;
          left: 10%;
          height: 80%;
        }

        @include xpay-pre-md {
          top: 10px;
          left: 0;
          height: 80%;
        }
      }
      // img.shape.machine {
      //   top: -25%;
      //   height: 108%;

      //   @include xpay-pre-xl {
      //     top: -85px;
      //     left: -5%;
      //     height: auto;
      //   }
      //   @include xpay-pre-lg {
      //     top: -10px;
      //     left: 5%;
      //     height: 120%;
      //   }
      //   @include xpay-pre-md {
      //     top: 10px;
      //     left: 0;
      //     height: 80%;
      //   }
      // }
    }
  }
  .swiper-slide:nth-child(2) .xpay-slide img.shape {
    top: -4%;
    left: 5%;

    @include xpay-pre-lg {
      top: 10px;
      left: 5%;
      height: 80%;
    }
  }
  .slide-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: url('./../../../public/assets/images/slides/mask.svg');
    background-repeat: no-repeat;
    background-size: cover;
  }
}
