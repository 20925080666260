@import 'styles/references/index.scss';
.order-confirm-modal {
  .confirm-purchase {
    width: max-content !important;
    @include  xpay-pre-lg {
      width: 100% !important;
    }
    .confirm-modal-alert {
      width: 500px !important;
      margin-bottom: 0;
      margin-top: 24px;

      @include xpay-pre-lg {
        width: 100% !important  ;
      }
    }
  }
}
