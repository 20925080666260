@import './src/styles/references/index.scss';

.xpay-withdrawal-two-step-verify {
  display: flex;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 430px;
  border-radius: 20px;
  padding: 32px;
  overflow-y: visible !important;

  .card-body {
    margin: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    .xpay-button {
      margin-top: 24px;
      width: 100%;
      max-width: unset;

      button.info {
        background: linear-gradient(95.43deg, #3f62fe -25.7%, #678af5 112.1%);
        font-weight: 500;
        font-size: 16px;
      }
    }

    .head {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: column;
      margin: 0;

      > div:first-child {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        h4 {
          font-size: 26px;
          font-weight: 500;
          margin-bottom: 0;
        }

        > span {
          font-size: 30px;
          font-weight: 700;
        }

        svg {
          cursor: pointer;
        }
      }

      > p {
        margin-top: 15px;
        font-size: 14px;
        color: $bold-span;
        max-width: 90%;
      }
    }

    .thankyou-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding-right: 24px;
      flex-wrap: wrap;

      background-image: url('./../../../../../../public/assets/images/thankyou/sphere.svg');
      background-repeat: no-repeat;
      background-position: left;

      @include xpay-pre-lg {
        padding-right: 12px;
        padding-left: 12px;
      }

      .left-side {
        @include xpay-pre-lg {
          width: 100%;
          order: 1;
        }

        img {
          width: 100%;
          @include xpay-pre-lg {
            width: 100%;
          }
        }
      }

      .right-side {
        @include xpay-pre-lg {
          order: 2;
        }

        h3 {
          padding-top: 40px;
          font-size: 24px;
          margin-bottom: 24px;
        }

        p {
          max-width: 58ch;
        }
      }

      &.danger {
        background-image: url('./../../../../../../public/assets/images/thankyou/danger-sphere.svg');
        background-repeat: no-repeat;
        background-position: left;
      }

      h3 {
        padding-top: 40px;
        font-size: 32px;
        margin-bottom: 24px;
      }

      p {
        max-width: 58ch;
      }
    }

    .buttons {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      a {
        .xpay-button {
          width: 100% !important;
        }
      }

      .xpay-button {
        width: calc(50% - 8px) !important;

        button {
          height: 48px !important;
        }
      }
    }

    .main {
      width: 100%;
      margin-top: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      &.thank-you {
        .buttons {
          flex-wrap: wrap;

          &::before {
            content: '';
            display: inline-block;
            width: 100%;
            height: 0.5px;
            background: $medium-white;
            margin-top: 24px;
          }
        }

        > .row {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: start;

          > span:first-child {
            color: $gray-text;
          }

          .anticon {
            svg {
              path {
                fill: $primary;
              }
            }
          }
        }

        > .row.has-divider::before {
          content: '';
          display: inline-block;
          width: 100%;
          height: 0.5px;
          background: $medium-white;
          margin: 24px 0;
        }

        > .row.col2 {
          flex-wrap: wrap;

          .col {
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;

            img {
              margin-left: 8px;
              width: 20px;
              height: 20px;
            }

            > span:first-child {
              color: $gray-text;
            }

            > div:last-child {
              > span {
                font-weight: 500;
              }
            }
          }
        }

        span.en {
          font-family: Montserrat !important;
          font-weight: 600 !important;
          font-size: 12px !important;
        }
      }

      &.recovery {
        img {
          width: 100px;
          height: 100px;
        }

        p {
          font-family: 'Montserrat' !important;
          font-size: 20px;
          font-weight: 700;
        }
      }

      &.qr-code {
        img {
          width: 120px;
          height: 120px;
        }

        > div:last-child {
          margin-top: 10px;

          .anticon {
            margin-right: 5px;
          }
        }
      }

      &.download {
        p {
          width: 100%;
          font-size: 12px;
          width: 100%;
          color: $bold-span;
        }

        > div:last-child {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          margin-top: 16px;
        }
      }

      &.code {
        margin-top: 0;
        > .row {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          > span:first-child {
            color: $gray-text;
          }

          .anticon {
            svg {
              path {
                fill: $primary;
              }
            }
          }
        }

        > .row.has-divider::before {
          content: '';
          display: inline-block;
          width: 100%;
          height: 0.5px;
          background: $medium-white;
          margin: 24px 0;
        }

        > .row.col2 {
          flex-wrap: wrap;

          .col {
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;

            img {
              margin-left: 8px;
              width: 20px;
              height: 20px;
            }

            > span:first-child {
              color: $gray-text;
            }

            > div:last-child {
              > span {
                font-weight: 500;
              }
            }

            .en.fee {
              direction: ltr;
              display: flex;
            }
          }
        }

        span.en {
          font-family: Montserrat !important;
          font-weight: 600 !important;
          font-size: 12px !important;
          direction: ltr;
        }

        .input {
          direction: ltr;
          margin-top: 24px;
          width: auto !important;

          > div {
            display: flex;
            justify-content: space-evenly;
            align-items: center;

            > input {
              margin: 0 10px;
              border: 1px solid $table-border;
              border-radius: 10px;
              max-height: 48px;

              @include xpay-pre-md {
                margin: 0 5px;
              }
            }
          }
        }

        .input.has-error {
          input {
            border-color: $danger;
          }
        }

        .description::before {
          content: '';
          display: inline-block;
          width: 100%;
          height: 0.5px;
          background: $medium-white;
          margin: 24px 0;
        }

        .description {
          font-weight: 500;
        }

        .input.has-error {
          input {
            border-color: $danger;
          }
        }

        .stats {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: column;
          width: 100%;
          margin: 10px 0;

          span.resend {
            cursor: pointer;
            color: $info;
          }

          .timer {
            justify-self: flex-start;
            display: flex;
            justify-content: center;
            align-items: center;

            > div {
              left: auto !important;
              top: auto !important;

              > svg {
                display: none;
              }

              > div {
                position: relative !important;
                height: 30px !important;
                width: 30px !important;
              }
            }
          }

          .errors {
            display: flex;
            justify-self: flex-start;
            justify-content: flex-start;
            align-items: center;
            column-gap: 4px;
            color: $danger;
            font-size: 12px;
            min-width: 50px;
          }

          > span {
            min-width: 50px;
          }
        }

        .timer {
          justify-self: center;
          display: flex;
          justify-content: center;
          align-items: center;

          > div {
            left: auto !important;
            top: auto !important;

            > svg {
              display: none;
            }

            > div {
              position: relative !important;
              height: 30px !important;
              width: 30px !important;
            }
          }
        }

        .change-number {
          margin-top: 10px;
          color: $info;
          cursor: pointer;
        }
      }

      &.app-code {
        .input {
          direction: ltr;
          width: auto !important;

          > div {
            display: flex;
            justify-content: space-evenly;
            align-items: center;

            > input {
              margin: 0 5px;
              border: 1px solid $table-border;
              border-radius: 10px;
              max-height: 48px;

              @include xpay-pre-md {
                margin: 0 5px;
              }
            }
          }
        }
      }
    }
  }
}
