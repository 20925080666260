@import "src/styles/references/index.scss";

.xpay-widthrawl-page {
  width: 100%;
  height: max-content;
  column-gap: 5px;
  background: $white;
  border-radius: 20px;
  overflow: hidden;

  margin-bottom: 8px;
  @include xpay-pre-lg {
    width: 100%;
    background: $bg;
    padding: 0 24px;

    .ant-row {
      width: 95% !important;
      margin: 0 auto !important;
    }
  }
  @include xpay-pre-md {
    width: 100%;
    padding: 0;
    margin: 0 auto;
  }

  .right-side {
    padding: 32px;

    @include xpay-pre-lg {
      padding: 0px;
      width: 95%;
    }
    .xpay-transaction-box {
      margin: 0 auto;
      border: 0px !important;

      input {
        @include xpay-pre-lg {
          font-size: 16px;
        }
      }
      &.sell {
        .calculator .field:first-child .symbol {
          border-radius: 10px;
        }
      }
      &.buy {
        .calculator .field:nth-child(2) .symbol {
          border-radius: 10px;
        }
      }
      .xpay-alert {
        margin-top: 32px !important;
      }
      .calculator {
        margin-top: 24px;
        .field {
          .symbol {
            width: 45%;
          }
        }
      }
    }
  }
  .left-side {
    background: $deposit-shadow;
    box-shadow: 0px 0px 20px 0px rgb(19 42 43 / 8%);

    @include xpay-pre-lg {
      box-shadow: unset;
      margin-top: 24px;
      border-radius: 10px;

      .xpay-helper-card {
        border-radius: 10px !important;
      }
    }
  }
}
.help-bar-row {
  @include xpay-pre-lg {
    margin: 16px auto;
    width: 90%;
  }
}
