@import "src/styles/references/index";

.xpay-price-box {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-content: center;
  background: $white;
  border-radius: 10px;
  border: 1px solid $table-border;
  height: auto;
  margin: 12px auto;
  margin-right: 0px !important;
  width: 100%;

  @include xpay-pre-lg {
    margin: 16px auto;
    max-width: unset;
  }
  .ant-divider {
    opacity: 0.45;
    margin: 0px !important;
  }
  .box-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 24px;

    @include xpay-pre-lg {
      padding: 12px 20px 3px 20px;
    }

    .right-side {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: max-content;
      img {
        height: 52px;
      }
      .name {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: flex-start;
        margin-right: 10px;

        span:first-child {
          font-size: 18px;
          font-weight: 300;
        }
        span:nth-child(2) {
          font-family: monospace;
          opacity: 0.4;
          font-size: 15px;
          font-weight: 600;
        }
      }
    }
    .left-side {
      direction: ltr;
      text-align: left;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      width: max-content;
      span:first-child {
        font-size: 16px;
        font-weight: bold;
        font-family: "Montserrat";
      }
      .change {
        display: flex;
        align-items: center;
        font-family: "Montserrat" !important;

        &.up {
          color: $success;
        }
        &.down {
          color: $danger;
        }
      }
    }
  }
  .box-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 24px;

    @include xpay-pre-lg {
      padding: 3px 20px 12px 20px;
    }

    .right-side {
      display: flex;
      flex-direction: column;
      text-align: right;
      width: max-content;
      align-items: flex-start;
      span:first-child {
        color: $gray-span;
        font-size: 12px;
      }
      span:nth-child(2) {
        color: $bold-span;
        font-weight: 300;
      }
    }
    .left-side {
      display: flex;
      flex-direction: column;
      text-align: left;
      width: max-content;
      align-items: flex-end;
      span:first-child {
        color: $gray-span;
        font-size: 12px;
      }
      span:nth-child(2) {
        color: $bold-span;
        font-weight: 300;
      }
    }
  }
}
