@import 'styles/references/index';

.mobile-layout-circle {
  display: none;
  position: absolute;
  bottom: 0;
  left: 0%;
  z-index: 0;
  width: 100%;

  svg {
    width: 100%;
  }

  @include xpay-pre-lg {
    display: block;
  }

  @media only screen and (max-height: 600px) {
    display: none !important;
  }
}

.app-info {
  position: absolute;
  bottom: 128px;
  left: 0;
  display: flex !important;
  justify-content: center;
  align-items: center;
  z-index: 10;
  width: 100%;

  &.mobile-show {
    @include xpay-pre-lg {
      display: flex !important;
    }
    @media only screen and (max-height: 600px) {
      display: none !important;
    }
  }

  &.desktop-show {
    bottom: 16px;

    .copyright {
      flex-direction: row;
      align-items: center;
      justify-content: center;
      height: auto;
      margin-top: 24px;

      > a {
        margin-top: 0;
      }
    }
  }

  .footer-logo {
    img {
      width: 50px;
    }
  }

  .copyright {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 60px;

    .year,
    a {
      font-size: 14px;
      font-family: 'Montserrat';
      font-weight: 500;
    }

    a {
      font-family: 'Montserrat' !important;
      font-weight: 700;
      margin-top: 16px;
    }
  }
}

.xpay-signin {
  position: relative;
  height: 100vh;
  display: flex;
  padding: 30px;
  justify-content: center;
  align-items: center;
  max-width: 1920px;
  margin: 0 auto;
  width: 100%;
  z-index: 1;

  @include xpay-pre-lg {
    flex-direction: column;
    padding: 24px 24px 0px;
    height: 100%;
  }

  .signin-right {
    position: relative;
    height: 100%;
    max-width: 651px;
    max-height: 720px;
    align-self: center;
    width: 35%;
    z-index: 0;
    @include xpay-pre-lg {
      display: none;
    }
    text-align: center;
    color: $white;

    h2 {
      color: $white;
    }

    .xpay-signin-slider {
      width: 100%;
      height: 100%;

      @include xpay-pre-lg {
        position: absolute;
        top: 0px;
        left: 0;
        width: 100%;
        height: 200%;
      }

      @include xpay-pre-md {
        height: 100%;
      }

      .swiper {
        height: 100%;
        @include xpay-pre-lg {
          height: 50%;
          margin-bottom: 24px;
        }
        @include xpay-pre-md {
          height: 100% !important;
          margin-bottom: 0;
        }
      }

      .swiper-slide {
        border-radius: 20px;

        @include xpay-pre-lg {
          border-top-right-radius: unset;
          border-top-left-radius: unset;
        }
      }

      .slide-content {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        height: 100%;
        padding: 64px 20px;

        h2 {
          @include xpay-size(font-size, 28px, 14px);
        }

        p {
          @include xpay-size(font-size, 16px, 10px);
        }

        @include xpay-pre-lg {
          padding: 34px 20px;
        }

        .footer {
          margin-top: auto;
        }

        img {
          width: 85%;
          @include xpay-pre-lg {
            width: 45%;
          }

          @include xpay-pre-md {
            width: 90%;
          }

          @include xpay-pre-sm {
            width: 80%;
          }

          @include xpay-pre-xs {
            width: 60%;
          }
        }
      }
    }
  }

  .signin-left {
    position: relative;
    width: 60%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 1;

    @include xpay-pre-lg {
      width: 100%;
      height: 100%;
    }

    @include xpay-pre-md {
      width: 100%;
    }

    .signin-stage {
      @include xpay-pre-md {
        width: 100%;
      }
    }

    .back-btn {
      position: absolute;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      left: 0;
      top: 25px;
      cursor: pointer;

      @include xpay-pre-lg {
        display: none;
      }
    }

    .back-btn.disabled {
      opacity: 0.5;
    }

    h3 {
      font-size: 24px;
      padding: 10px 0px 20px;
    }
  }
}
