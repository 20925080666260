@import 'styles/references/index.scss';

.xpay-dashboard-referral .referral-invited > .card-body > .mobile-show li {
  > .row {
    .right {
      justify-content: flex-start;
      align-items: center;

      p,
      span {
        margin-left: 8px;
        @include xpay-pre-lg {
          font-size: 12px;
        }
      }

      p {
      }

      span {
        font-weight: 500;
      }
    }

    .left {
      justify-content: flex-end;
      align-items: center;

      .xpay-badge {
        .info {
          background-color: $light-cyan-badge;
          color: $info;
          border: 1px solid $info;
          padding: 4px 16px;
        }
      }

      p,
      span {
        margin-right: 3px;
        @include xpay-pre-lg {
          font-size: 12px;
        }
      }

      p {
      }

      span {
        font-weight: 500;
      }
    }
  }
}
