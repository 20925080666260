@import "src/styles/references/index";

.xpay-credit-card {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  max-width: 335px;
  width: 95%;
  height: 215px;
  background: $primary;
  border-radius: 10px;
  color: $white;
  margin: 24px 25px;

  .card-state-badge {
    display: flex;
    align-items: center;
    border-radius: 10px;
    width: max-content;
    padding: 4px 6px;
    position: absolute;
    top: -14px;
    z-index: 99;
    &.warning {
      background: rgba(232, 152, 6);
      backdrop-filter: blur(10px);
    }
    &.success {
      background: rgba(26, 183, 89);
      backdrop-filter: blur(10px);
    }
    &.danger {
      background: rgba(233, 60, 60);
    }
    .anticon {
      margin-bottom: 2px;
    }

    span:nth-child(2) {
      margin-right: 6px;
    }
  }

  .bg-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-image: url("./../../../../../public/assets/images/credit-cards/circles.svg");
    z-index: 2;
  }

  .card-bank {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 30px 20px 10px 20px;
    z-index: 3;

    h4 {
      color: $white;
      font-size: 22px;
    }

    .card-delete {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px;
      border-radius: 7px;
      background: rgba(255, 255, 255, 0.1);
      column-gap: 10px;
      cursor: pointer;

      span {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }

  .card-number {
    position: relative;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row-reverse;
    width: 100%;
    padding: 20px;
    column-gap: 5px;
    z-index: 3;
    direction: ltr;

    span {
      font-family: "Montserrat";
      font-weight: bold;
      font-size: 20px;
      letter-spacing: 4px;
    }
  }

  .card-shaba {
    font-family: "Montserrat";
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background: $primary;
    width: 100%;
    height: 76px;
    z-index: 3;
    font-size: 16px;
  }
}
