@import 'styles/references/index.scss';

form.form-selfie {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  row-gap: 40px;

  .progress-bar-container {
    width: 100%;
  }

  @include xpay-pre-lg {
    row-gap: 24px;
  }

  .ant-row {
    width: 100%;
    margin-bottom: 16px;
    box-sizing: border-box;
    display: flex;
    flex: 1;

    .xpay-dropdown {
      max-width: unset;
      flex: 1 0;

      &:last-child {
        margin-right: 16px;
      }

      .dropdown-selector {
        justify-content: space-between;
      }

      .dropdown-items {
        max-width: unset;
      }
    }

    .xpay-input {
      width: 100%;
    }
  }

  #selfie-photo > span,
  #doc-photo > span {
    width: 100%;
  }

  .input-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 30px;
    width: 100%;

    @include xpay-pre-lg {
      flex-direction: column;
    }
  }

  .browse-field {
    position: relative;
    border: 1px solid $table-border;
    padding: 5px;
    width: 100%;
    height: max-content;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;

    @include xpay-pre-lg {
      width: 100%;
      margin-top: 24px;
    }

    &:last-child {
      @include xpay-pre-lg {
        margin-top: 32px;
      }
    }

    label {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      top: -28px;
      left: 0;
      width: 100%;

      > span {
        @include xpay-pre-lg {
          font-size: 10px;
        }
      }

      > span:first-child {
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 3px;

        span {
          font-size: 12px;
        }
      }

      > .sample {
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 3px;
        color: $white;
        border-radius: 10px;
        background: $primary;
        padding: 3px;
      }
    }

    .browse {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 100%;

      .selfie-uploader,
      .document-uploader {
        width: 100%;
        height: 100%;

        .ant-upload {
          margin: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          border: 0.6px dashed $transparent-blue;
          border-spacing: 10px;
          border-radius: 5px;

          button {
            background: transparent;
            border: 0;
            color: $info;

            span.mobile-show {
              display: none;
              @include xpay-pre-lg {
                display: inline-block;
              }
            }

            span.desktop-show {
              @include xpay-pre-lg {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  .xpay-button {
    max-width: unset;
  }
}

.xpay-help-modal {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 328px;
  background: white;
  max-width: 328px;
  text-align: center;
  border-radius: 16px;
  padding: 16px;

  img {
    width: 100%;
  }

  .modal-title,
  .modal-description {
    margin-bottom: 24px;
  }

  .player {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 18px auto;
    flex-direction: column;
    row-gap: 25px;

    div {
      width: 100% !important;
      height: auto !important;

      video {
        border-radius: 16px;
        height: max-content !important;
        text-align: center;
        max-width: 640px;
      }
    }
  }

  p {
    text-align: right;
    padding: 8px;
  }

  .xpay-button {
    margin-top: 10px;
    width: 100%;
    max-width: unset;
  }
}
