@import "src/styles/references/index";
@import "src/styles/animations.scss";

.xpay-level-info-card {
  position: relative;
  background: $white;
  border-radius: 10px;
  width: 100%;
  max-width: 425px;
  min-height: 63px;
  height: max-content;
  margin: 24px auto 0;
  padding: 17px;
  height: max-content;
  transition: 1s;
  cursor: pointer;
  box-shadow: 0px 0px 20px 0px rgb(19 42 43 / 8%);
  &.checked {
    border: 1px solid $info;
  }

  &.open {
    .level-card-body {
      animation: xpay-fade-in 1.4s;
      height: max-content !important;
    }
  }
  &:not(.open) {
    .level-card-body {
      animation: xpay-fade-out 1.4s;
    }
  }
  .level-badge {
    position: absolute;
    padding: 5px 10px;
    top: -15px;
    border-radius: 10px;
    left: calc(50% - 40px);
    background: $level-badge-bg;
    color: $white;
  }

  .level-checked {
    position: absolute;
    padding: 8px 6px;
    top: -15px;
    border-radius: 50%;
    left: calc(50% - 17px);
    background: $primary;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
    }
  }

  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span.name {
      font-weight: 500;
    }
    .ant-rate {
      color: $info;
      font-size: 16px !important;
    }
    .ant-rate-star:not(:last-child) {
      margin-right: 4px !important;
    }
    .check-advantages {
      display: flex;
      align-items: center;
      &:hover {
        cursor: pointer !important;
      }
      .anticon {
        margin-bottom: 2px;
        margin-right: 4px;
        color: $info;
      }
    }
  }

  .level-card-body {
    text-align: right;
    hr {
      opacity: 0.2;
      padding: 4px;
    }
    .accesses-list {
      span:first-child {
        font-weight: 500;
      }
      li {
        display: flex;
        align-items: center;
        font-size: 12px;
      }
      .anticon,
      li span {
        color: $access;
      }
      li span {
        margin-right: 4px;
      }
    }
    .limitations-list {
      span:first-child {
        font-weight: 500;
      }
      li {
        display: flex;
        align-items: center;
      }
      .anticon,
      li span {
        color: $gray-span;
      }
      li span {
        margin-right: 4px;
      }
    }
    ul {
      list-style: none;
      padding-right: 2px !important;
    }

    .required-info {
      > span:first-child {
        font-weight: 300;
      }
      .requirement-item {
        margin: 19px 0px;
        display: flex;
        align-items: center;
        .icon {
          width: max-content;
          background: $info;
          border-radius: 10px;
          width: 32px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          .anticon {
            font-size: 16px;
            color: $white;
          }
        }

        .info {
          margin-right: 8px;

          > span {
            > span {
              font-size: 10px;
            }
          }

          p {
            color: $gray-span;
            font-size: 10px;
          }
        }
      }
    }
    .level-note {
      span:first-child {
        display: flex;
        align-items: center;
        color: $warning;
        font-weight: 500;
        font-size: 16px;
      }

      img {
        margin: 5px;
      }

      p {
        font-size: 12px;
      }
    }
    .level-options {
      ul li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 16px 0px;
      }
    }
  }
}
