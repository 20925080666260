@import 'styles/references/index.scss';

.xpay-balance {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
  row-gap: 15px;
  padding-top: 25px;

  @include xpay-pre-xl {
    padding-top: 10px;
  }

  @include xpay-pre-lg {
    padding-top: 0;
  }

  .balance-card {
    @include xpay-size(border-radius, 10px, 24px);
    color: $white;
    position: relative;
    width: 100%;
    min-height: 170px;
    max-height: 250px;
    padding: 15px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    row-gap: 20px;
    background: $primary;

    // @include xpay-size(width, 425px, 275px);
    // @include xpay-size(height, 250px, 200px);

    @include xpay-pre-lg {
      width: 100%;
      max-width: unset;
    }

    h3,
    h4 {
      color: $white;
    }

    .balance-card-overlay {
      background: url('./../../../../../public/assets/images/slides/mask.svg');
      background-size: cover;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.7;
    }

    .balance-card-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      h4 {
        font-size: 18px;
      }

      .more {
        display: none;
        background: rgba(255, 255, 255, 0.2);
        padding: 8px 20px;
        border-radius: 8px;

        img {
          margin-right: 5px;
          width: 7px;
          height: 7px;
        }
      }
    }

    .balance-card-main {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      column-gap: 5px;

      h3 {
        font-size: 26px;
      }

      span {
        font-family: 'Montserrat';
        font-size: 12px;
        font-weight: 400;
      }
    }

    .balance-card-footer {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      column-gap: 15px;

      .export {
        background: rgba(255, 255, 255, 0.2);
        padding: 3px;
        border-radius: 100%;

        img {
          width: 20px;
          height: 20px;
        }
      }
      > span:nth-child(2) {
        margin-right: -3px;
        font-size: 14px;
        font-weight: 500;
      }
      > span:last-child {
        margin-right: -3px;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }

  /* deposit and withdrawal button */

  .balance-buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    column-gap: 10px;

    @include xpay-pre-lg {
      column-gap: 10px;
      width: 100%;
      max-width: unset;
    }

    @include xpay-pre-xs {
      column-gap: 10px;
      justify-content: space-between;
    }

    .xpay-deposit-button,
    .xpay-withdrawal-button {
      min-height: 55px;
      margin: 0 0;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      width: 45%;

      @include xpay-pre-lg {
        margin: 0;
      }

      button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 12px 15px 12px;
      }
    }

    .xpay-deposit-button > button {
      background: $secondary;
    }

    .xpay-withdrawal-button > button {
      background: $primary;
    }
  }
}
