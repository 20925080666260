@import 'styles/references/index.scss';

.xpay-helper-card {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  row-gap: 20px;
  width: 100%;
  height: auto;
  background: $white;
  padding: 20px;
  border-radius: 20px;

  .title {
    color: $info;
    display: flex;
    align-items: center;
    width: 100%;

    span {
      margin-right: 5px;
    }
  }

  ul {
    width: 100%;
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    flex-direction: column;
    row-gap: 20px;

    li {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      p {
        span {
          margin-left: 3px;
          display: inline-block;
          background: $primary;
          color: $white;
          border-radius: 50%;
          width: 20px;
          height: 20px;
          max-width: 20px;
          padding-top: 2px;

          text-align: center;
          vertical-align: middle;
        }
      }
    }
  }
}
