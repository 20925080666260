@import "src/styles/references/index.scss";

.xpay-container {
  display: flex;
  background: $bg;
  width: 100%;
  height: 100vh;
  overflow-y: hidden;
  margin: auto;
  column-gap: 32px;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    width: 0px;
  }

  ::-webkit-scrollbar-thumb {
    background: $scroll-thumb-bg;
  }

  ::-webkit-scrollbar-track {
    background: $white;
  }
  @include xpay-pre-lg {
    // padding: 0 2.5%;
    height: 100%;
  }
}
