@import 'styles/references/index.scss';

.xpay-deposit-currency-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  row-gap: 10px;
  border: 1px solid $table-border;
  border-radius: 10px;
  padding: 15px;
  width: 100%;
  height: 100%;
  margin: 0 auto;

  .anticon.anticon-caret-down {
    color: $info;
  }

  @include xpay-pre-xl {
    height: auto;
  }

  @include xpay-pre-lg {
    width: 100%;
    max-width: unset;
    border: none;
    border-radius: 20px;
    padding: 0;
  }

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    row-gap: 10px;
    width: 100%;
    height: 100%;

    @include xpay-pre-lg {
      display: flex;
      border-radius: 10px;
      padding: 0px;
      background: $white;
      row-gap: unset;
    }
  }

  .xpay-switch-bar {
    button {
      @include xpay-pre-lg {
        border-radius: 10px;
      }
    }
  }


  .deposit-options {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    row-gap: 24px;
    margin: 19px auto;

    @include xpay-pre-lg {
      margin: 24px auto;
      row-gap: 16px;
    }

    .xpay-alert {
      margin: 0 auto 24px auto;
    }

    .xpay-alert.warning {


      .alert-content {
        width: 100%;
        max-width: unset;

        .message {
          width: 100%;
          text-align: justify;
          font-size: 14px;
        }
      }
    }

    .xpay-alert.warning.mobile-show {
      margin-top: 0px !important;
      margin-bottom: 0 !important;

      .alert-content {
        width: 100%;
        max-width: unset;

        .alert-right-side {
          width: 100%;
        }

        .message {
          margin-top: 32px;
          width: 100%;
          font-size: 14px;

          ul {
            display: flex;
            padding: 0 8px;
            margin: 0;
            flex-direction: column;

            li {
              list-style-type: disc;
              display: flex !important;
              align-items: center;
              justify-content: space-between;
              margin-top: 10px;

              &:first-child {
                margin-top: 0;
              }

              > span {
                font-size: 16px;
                display: list-item;
                text-align: right;

                @include xpay-pre-lg {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }

    .xpay-alert.info.mobile-show {
      .message {
        margin-top: 32px;
      }
    }

    .xpay-alert.info {
      margin-top: 0px !important;
      margin-bottom: 0 !important;

      .alert-content {
        width: 100%;
        max-width: unset;

        .message {
          width: 100%;

          .purchase-info {
            display: flex;
            flex-direction: column;

            > span {
              font-size: 16px;
              font-weight: 400;

              @include xpay-pre-lg {
                font-size: 14px;
              }
            }

            ul {
              display: flex;
              padding: 0;
              margin: 0;
              flex-direction: column;
              margin-top: 16px;

              li {
                padding: 0;
                margin: 0;
                list-style-type: none;
                display: flex;
                align-items: center;
                justify-content: space-between;
                box-sizing: border-box;
                flex-wrap: nowrap;
                margin-top: 24px;

                &:first-child {
                  margin-top: 0;
                }

                > span {
                  font-size: 16px;
                  font-weight: 500;
                  width: max-content;
                  text-wrap: nowrap;

                  .anticon {
                    margin-right: 4px;
                    cursor: pointer;
                  }

                  @include xpay-pre-lg {
                    font-size: 14px;
                  }
                }

                .dotted-divider {
                  display: block;
                  width: 100%;
                  margin-right: 1%;
                  margin-left: 1%;
                  height: 1px;
                  border-top: 1px dashed $gray-span;
                }
              }
            }
          }
        }
      }
    }

    .deposit-value-row {
      width: 100%;
      text-align: left;
      margin-bottom: 11px;

      .input-info-labels {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .input-info-label {
        font-size: 11px;

        > span:last-child {
          color: $info !important;
          font-weight: 500;
          margin-right: 4px;
        }

        &:first-child {
          text-align: left;
          margin-left: auto;
          font-weight: 500;
        }

        &:last-child {
          margin-right: auto;
          color: $info !important;
          font-weight: unset;
        }
      }
    }

    .payment-options-row {
      width: 100%;
      text-align: left;

      .input-info-labels {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .input-info-label {
        font-size: 11px;

        > span:last-child {
          color: $info !important;
          font-weight: 500;
          margin-right: 4px;
        }

        &:first-child {
          text-align: left;
          margin-left: auto;
          font-weight: 500;
        }

        &:last-child {
          margin-right: auto;
          color: $info !important;
          font-weight: unset;
        }
      }

      .payment-cart-selector {
        position: relative;

        &:hover {
          cursor: pointer !important;
        }

        input {
          font-size: 18px;
          text-align: start;
          direction: ltr;
        }

        .carts-dropdown {
          position: absolute;
          top: 65px;
          left: 0px;
          width: 100%;
          background: $white;
          z-index: 998;
          border: 1px solid $table-border;
          border-radius: 4px;

          ul {
            padding: 0px;
            text-align: right;
            border-radius: 4px;
            list-style: none;
            margin: 0px;

            li {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 0px 16px;
              height: 48px;

              .bank-info {
                span {
                  margin-right: 8px;
                  direction: ltr;
                }

                img {
                  height: 24px;
                }
              }

              span {
                direction: ltr;
              }

              &:hover {
                cursor: pointer;
                background: $symbol-btn-bg;
              }
            }

            li:not(:last-child) {
              border-bottom: 1px solid $table-border;
            }
          }
        }

        .add-new-cart {
          border: 1px solid $table-border;
          border-radius: 10px;
          width: 100%;
          height: 52px;
          padding: 0px 16px;
          cursor: pointer;
          display: flex;
          align-items: center;

          span.anticon {
            margin-bottom: 3px;
          }

          span:nth-child(2) {
            margin-right: 8px;
          }
        }
      }

      .payment-recept-uploader {
        margin-top: 36px;

        .browse-field {
          width: 100%;
          position: relative;
          border: 1px solid $table-border;
          padding: 5px;
          height: 48px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 10px;

          label {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: absolute;
            top: -25px;
            left: 0;
            width: 100%;

            > span {
              font-size: 11px;
              font-weight: 500;
            }
          }

          .browse {
            display: flex;
            justify-content: center;
            width: 100%;
            height: 100%;

            .avatar-uploader {
              width: 100%;
              height: 100%;

              .ant-upload {
                margin: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                border: 0.6px dashed $transparent-blue;
                border-spacing: 10px;
                border-radius: 5px;

                button {
                  background: transparent;
                  border: 0;
                  color: $info;
                }
              }
            }
          }
        }
      }
    }

    .xpay-input {
      width: 100%;
      margin-top: 5px;

      @include xpay-pre-lg {
        border-radius: 10px;
      }

      .input-field {
        display: flex;
        align-items: center;
        height: 58px;
        padding: 0px 16px;

        > span:last-child {
          color: $gray-span;
        }

        input::placeholder {
          text-align: left;
        }

        .mark {
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;
          column-gap: 5px;
          min-width: 120px;
          min-height: 28px;

          &::after {
            content: '';
            border-left: 2px solid $ant-form-bg;
            padding: 2px;
            height: 26px;
          }

          .selected-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            column-gap: 5px;

            img,
            svg {
              width: 28px;
              height: 28px;
            }

            span {
              width: max-content;
              font-size: 12px;
              font-weight: 500;
            }

            span:last-child {
              font-family: 'Montserrat';
              color: $light-info;
            }
          }

          .selected-cart {
            display: flex;
            justify-content: space-between;
            align-items: center;
            column-gap: 5px;

            input {
              direction: ltr;
              text-align: start;
              font-size: 18px;
            }

            img,
            svg {
              width: 28px;
              height: 28px;
            }

            span {
              width: max-content;
              font-size: 16px;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}

.submit {
  width: 100%;

  .xpay-button {
    position: relative;
    max-width: unset;
    margin: 0;
    height: 48px;

    button {
      background: $primary;
      @include xpay-pre-lg {
        border-radius: 10px;
      }
    }

    button {
      @include xpay-pre-xl {
        height: 48px;
      }
    }
  }

  .xpay-button::before {
    content: ' ';
    position: absolute;
    width: 100%;
    height: 100%;
    background-position: -140px;
    background-repeat: no-repeat;
    background-attachment: local;
    background-size: 50%;
  }
}

.create-cart-modal {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 18px;
  width: 330px;
  background: $white;
  border-radius: 10px;
  z-index: 10;
  @include xpay-pre-lg {
    width: unset !important;
  }

  .head {
    width: 100%;

    h4 {
      text-align: center;
      font-size: 18px;
      font-weight: 500;
    }
  }

  .xpay-input {
    max-width: 324px !important;
  }

  .text {
    width: 100%;
    margin: 10px auto;

    p {
      text-align: center;
      color: $gray-span;
    }
  }

  .buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    row-gap: 16px;

    .xpay-button:first-child {
      margin-right: 0px;
    }

    @include xpay-pre-lg {
      flex-direction: row;
    }

    .xpay-button {
      max-width: unset !important;
      width: 324px;

      @include xpay-pre-lg {
        width: 45% !important;
      }

      button {
        height: 48px;

        &.outlined {
          border: 1px solid $gray-span !important;
          color: $gray-span;

          &:hover {
            background: transparent;
          }
        }
      }
    }
  }

  .input-field {
    display: flex;
    align-items: center;
    padding: 8px;
    height: 52px;

    img {
      height: 24px;
    }
  }
}

.carts-dropdown-modal {
  width: 724px;
  height: 473px;

  @include xpay-pre-lg {
    width: 327px;
    height: 442px;
  }

  .head {
    h4 {
      text-align: right !important;
    }
  }

  .carts-dropdown {
    width: 100%;
    background: $white;
    z-index: 998;
    border: 1px solid $table-border;
    border-radius: 4px;

    ul {
      padding: 0px;
      text-align: right;
      border-radius: 4px;
      list-style: none;
      margin: 0px;
      max-height: 361px;
      overflow-y: scroll;

      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 16px;
        height: 48px;

        .bank-info {
          span {
            margin-right: 8px;
            direction: ltr;
          }

          img {
            height: 24px;
          }
        }

        span {
          direction: ltr;
        }

        &:hover {
          cursor: pointer;
          background: $symbol-btn-bg;
        }
      }

      li:not(:last-child) {
        border-bottom: 1px solid $table-border;
      }
    }
  }
}
