@import 'styles/references/index.scss';

.xpay-profile-change-phone {
  display: flex;
  width: auto;
  height: auto;
  max-width: 430px;
  border-radius: 20px;
  padding: 32px;
  overflow-y: visible !important;

  .card-body {
    margin: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    .new-password {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;

      > h3 {
        @include xpay-pre-lg {
          display: none;
        }
      }

      .new-password-form {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 350px;
        row-gap: 30px;

        @include xpay-pre-md {
          width: 100%;
        }

        .new-password-input {
          display: flex;
          justify-content: center;
          align-items: flex-start;
          flex-direction: column;
          width: 100%;

          .input-field {
            display: flex;
            justify-content: center;
            align-items: center;
          }

          input {
            background: transparent;
            height: 40px;
          }

          .start-adornment {
            margin-right: 10px;
          }
          .end-adornment {
            margin-left: 10px;
          }
        }

        .new-password-button {
          max-width: 100%;
          margin: 0;
          button {
            width: 100%;
          }
        }
      }
    }

    .xpay-button {
      margin-top: 32px;
      width: 100%;
      max-width: unset;

      button.info {
        background: $primary;
        font-weight: 500;
        font-size: 16px;
      }
    }

    .head {
      width: 100%;
      min-width: 350px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: column;
      margin: 0;

      > div:first-child {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        h4 {
          font-size: 26px;
          font-weight: 500;
        }

        > span {
          font-size: 30px;
          font-weight: 700;
        }

        svg {
          cursor: pointer;
        }
      }

      > p {
        font-size: 14px;
        color: $bold-span;
        //max-width: 90%;
        width: 100%;
        text-align: center;
        padding: 0;
        margin: 0;
      }
    }

    .buttons {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .xpay-button {
        width: calc(50% - 8px);

        button {
          height: 48px !important;
        }
      }
    }

    .main {
      width: 100%;
      margin-top: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      &.code {
        .input {
          direction: ltr;
          width: auto !important;

          > div {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            > input {
              margin: 0 10px;
              border: 1px solid $table-border;
              border-radius: 10px;
              max-height: 48px;

              @include xpay-pre-md {
                margin: 0 5px;
              }
            }
          }
        }
        .input.has-error {
          input {
            border-color: $danger;
          }
        }

        .input.has-error {
          input {
            border-color: $danger;
          }
        }

        .stats {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: column;
          width: 100%;
          margin: 10px 0;

          span.resend {
            cursor: pointer;
            color: $info;
          }

          .timer {
            justify-self: flex-start;
            display: flex;
            justify-content: center;
            align-items: center;

            > div {
              left: auto !important;
              top: auto !important;
              > svg {
                display: none;
              }
              > div {
                position: relative !important;
                height: 30px !important;
                width: 30px !important;
              }
            }
          }
          .errors {
            display: flex;
            justify-self: flex-start;
            justify-content: flex-start;
            align-items: center;
            column-gap: 4px;
            color: $danger;
            font-size: 12px;
            min-width: 50px;
          }
          > span {
            min-width: 50px;
          }
        }

        .timer {
          justify-self: center;
          display: flex;
          justify-content: center;
          align-items: center;

          > div {
            left: auto !important;
            top: auto !important;
            > svg {
              display: none;
            }
            > div {
              position: relative !important;
              height: 30px !important;
              width: 30px !important;
            }
          }
        }
        .change-number {
          margin-top: 10px;
          color: $info;
          cursor: pointer;
        }
      }
    }
  }
}
