@import 'src/styles/references/index';

.xpay-alert {
  position: relative;
  min-height: 56px;
  padding: 16px;
  border-radius: 10px;
  border: 1px solid;
  margin: 0 auto;
  width: 100%;
  height: max-content;

  &:first-child {
    margin-top: 0;
  }

  &.mobile-show {
    display: none !important;

    @include xpay-pre-lg {
      display: flex !important;
    }
  }

  > .head-icon {
    border-radius: 30px;
    background: $warning;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    @include xpay-pre-lg {
      margin: 8px auto !important;
    }

    svg {
      width: 14px;
      height: 12px;
    }
  }

  .alert-box {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .alert-right-side {
      display: flex;
      align-items: start;
      flex-direction: row;
      width: 100%;

      .title {
        font-weight: 500;
      }

      .alert-icon {
        margin-left: 8px;
      }

      .alert-icon svg {
        height: 36px;
        margin-top: 2px;
      }
    }

    .alert-left-side {
      display: flex;
      align-self: start;

      > .ant-icon,
      > svg {
        margin-bottom: auto;
        margin-top: 0;
      }
    }
  }

  &.danger {
    background: $danger-badge;
    border-color: $danger;

    .title,
    .anticon-close {
      color: $danger;
    }
  }

  &.success {
    background: $success-badge;
    border-color: $success;

    .title,
    .anticon-close {
      color: $success;
    }
  }

  &.warning {
    background: $warning-alert;
    border-color: $warning;

    .title,
    .anticon-close {
      color: $warning;
    }
  }

  &.info {
    border-color: $info;

    background: $primary-alert;

    .title,
    .anticon-close {
      color: $info;
    }
  }

  .alert-box {
    display: flex;

    .alert-content {
      max-width: 90%;
      margin-bottom: 6px;

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      .title {
        font-size: 16px;

        @include xpay-pre-lg {
          font-size: 14px;
        }
      }

      .message {
        margin-top: 8px;
        color: $alert-message;
        font-weight: 400;
        text-align: right;
        font-size: 12px;
        @include xpay-pre-lg {
          font-size: 14px;
        }

        p {
          font-size: 16px;
          line-height: 29px;

          text-align: right;

          @include xpay-pre-lg {
            font-size: 14px;
            line-height: 25px;

          }
        }
      }
    }
  }
}
