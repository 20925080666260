@import 'src/styles/references/index.scss';

.xpay-wallet-page {
  @include xpay-pre-lg {
    padding: 0 24px;
  }
  .xpay-panel-card {
    min-height: 141px;
    height: max-content;
    justify-content: space-between;

    margin-bottom: 0px !important;
    @include xpay-pre-lg {
      margin-top: 0;
    }

    .head {
      margin-bottom: 0px;
    }

    .card-body {
      width: 100%;
      margin: 0px;
    }

    .main-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .right-side {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .irt-balance {
          font-weight: 900;
          font-size: 24px;
        }

        .usdt-balance {
          font-family: monospace;
          font-weight: 300;
          margin-right: 4px;
        }
      }

      .left-side {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;

        .timer {
          display: flex;
          gap: 16px;
          justify-content: center;
          align-items: center;

          @include xpay-pre-lg {
            flex-direction: column;
            gap: unset;
          }

          span {
            font-size: 16px;
            font-weight: 500;

            @include xpay-pre-lg {
              font-size: 14px;
              text-align: center;
            }
          }

          .ant-statistic {
            margin-inline: auto;
            width: 100px;

            .ant-statistic-content {
              span {
                font-size: 20px;
                letter-spacing: 4px;

                @include xpay-pre-lg {
                  font-size: 18px;
                }
              }
            }
          }
        }

        .xpay-button {
          margin: unset;
          button {
            height: 38px;
          }
        }
      }
    }

    .chart-row {
      .recharts-wrapper {
        width: 100%;

        .recharts-legend-wrapper {
          right: 486px !important;
          height: max-content;
          width: 100% !important;

          ul.recharts-default-legend {
            display: flex;
            align-items: center;
            text-align: right !important;
            flex-wrap: wrap;
            row-gap: 24px;

            li {
              align-items: baseline;
              flex: 40%;

              span {
                width: max-content !important;
                margin-right: 6px;
              }
            }
          }
        }
      }
    }
  }

  .assets-row {
    margin-bottom: 80px;

    .head {
      margin-top: 32px;
      margin-bottom: 24px;
      display: flex;
      justify-content: space-between;
      width: 100%;

      @include xpay-pre-lg {
        flex-direction: column;
        margin: 32px 0;
      }

      .checkbox {
        &.desktop-show {
          @include xpay-pre-lg {
            display: none;
          }
        }
        .ant-checkbox-wrapper {
          margin-left: 8px;
        }
      }

      .mobile-show {
        width: 100%;
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        .ant-row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;

          &:not(:last-child) {
            margin-bottom: 16px;
          }
        }

        @include xpay-pre-lg {
          display: flex;
          margin-bottom: 16px;
        }
      }

      .xpay-input {
        background: $white;
        border-radius: 10px;
        width: 330px;

        @include xpay-pre-lg {
          width: 100%;
        }

        .input-field {
          display: flex;
          align-items: center;
          padding: 0px 16px;

          .anticon {
            svg {
              path {
                color: $info;
              }
            }
          }
        }
      }

      .sort {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        > .xpay-dropdown {
          margin-right: 16px;
          @include xpay-pre-lg {
            display: none;
          }
        }
      }
    }

    .mine-assets-list {
      width: 100%;

      .desktop-show {
        padding: 0px !important;
      }
    }
  }
}
