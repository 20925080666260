@import 'src/styles/references/index';

.orders-menu-modal {
  .orders-menu-list {
    h4 {
      font-size: 13px !important;
      font-weight: 300;
    }
  }
}

.xpay-bottom-menu {
  position: fixed;
  display: none;
  bottom: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 80px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background: $white;

  @include xpay-pre-lg {
    display: flex;
  }
}
