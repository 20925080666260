@import "styles/references/index.scss";

.xpay-notice {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    @include xpay-pre-lg {
        padding: 0 16px;
    }
    span {
        color: $warning;
        font-size: 12px;
    }

    .right-side {
        padding: 0px !important;
    }
    .left-side,
    .right-side {
        display: flex;
        width: max-content;
        height: max-content;
    }
    .right-side {
        justify-content: start;
        align-items: center;

        span:last-child {
            margin-right: 8px;
        }
    }
    .left-side {
        justify-content: end;
        align-items: center;
    }
}
