@import 'styles/references/index.scss';

.xpay-dashboard-bank-account {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 70px;
  @include xpay-pre-lg {
    margin: 70px 0;
  }
  .desktop-show {
    @include xpay-pre-lg {
      display: none;
    }
  }

  .xpay-alert.warning {
    margin: 0px 0;
    .alert-content {
      width: 100%;
      max-width: unset;
      .message {
        width: 100%;
        text-align: justify;
        font-size: 14px;
      }
    }
  }

  .mobile-show {
    display: none;
    @include xpay-pre-lg {
      display: flex;
    }
  }

  @include xpay-pre-lg {
    margin: 0px 0 32px;
    padding: 0 24px;
  }
  .bank-accounts {
    border-radius: 20px;
    width: 100%;
    min-height: 100px;
  }
  .create-bank-account {
    width: 100%;
    margin: 24px auto 0;
    @include xpay-pre-lg {
      width: 100%;
    }
  }

  .xpay-helper-box {
    margin-top: 24px;
  }
}
