@import 'styles/references/index.scss';

form.form-basic {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  row-gap: 40px;

  @include xpay-pre-lg {
    row-gap: 0;
  }

  > div {
    display: flex;
    justify-content: flex-start;
    align-items: end;
    column-gap: 20px;
    width: 100%;

    @include xpay-pre-lg {
      flex-direction: column;
      width: 100%;
    }

    .birth-date {
      position: relative;
      width: 50%;
      height: max-content;

      @include xpay-pre-lg {
        margin-top: 20px;
        width: 100%;
      }

      label {
        font-size: 12px;
      }

      div {
        margin-top: 6px;
      }

      .xpay-input {
        text-align: left;
        width: 100%;
      }
    }

    &:first-child > .xpay-input:first-child {
      @include xpay-pre-lg {
        margin-top: 24px;
      }
    }

    .xpay-input {
      width: 50%;

      @include xpay-pre-lg {
        width: 100%;
        margin-top: 40px;
      }

      .input-field {
        display: flex;
        justify-content: space-between;
        align-items: center;

        svg {
          margin: 0 20px;
        }
      }
    }
  }

  > div:nth-child(3) {
    width: 49%;

    @include xpay-pre-lg {
      width: 100%;
    }
  }

  .xpay-button {
    max-width: unset;

    @include xpay-pre-lg {
      margin-top: 32px;
    }
  }
}

.verify-info-modal {
  width: 500px;
  .modal-content {
    h4 {
      margin: 0;
    }
    .confirm-box {
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;

        li {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          span {
            opacity: 0.5;
          }

          p {
            font-weight: 500;
          }
        }
      }

      .buttons {
        display: flex;
        flex-direction: column;
        row-gap: 16px;

        .xpay-button:last-child {
          margin-right: 0;

          button {
          }
        }
      }
    }
  }
}
