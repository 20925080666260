@import 'styles/references/index';

.password-reset-stage {
  width: 350px;
  text-align: center;

  @include xpay-pre-md {
    width: 100%;
  }

  > h3 {
    @include xpay-pre-lg {
      display: none;
    }
  }

  .password-reset-main {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 40px;

    .password-reset-input {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      width: 100%;
      margin-bottom: 20px;

      .input-field {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 10px;
      }

      input {
        background: transparent;
        height: 40px;
      }

      .start-adornment {
        margin-right: 10px;
      }
      .end-adornment {
        margin-left: 10px;
      }
    }
  }
  .password-reset-button {
    max-width: 100%;
    width: 100%;
    margin: 0;
  }
}
