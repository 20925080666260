@import 'styles/references/index.scss';

.xpay-helper-box {
  border-radius: 20px;
  width: 100%;
  padding: 0;
  background: $white;

  &.mobile-show {
    @include xpay-pre-lg {
      display: block !important;
    }
  }

  > .card-body {
    margin: 0;
    padding: 20px 16px;
    width: 100%;
    background: initial;

    ul,
    .xpay-helper-card {
      width: 100%;
      margin: 0;
      padding: 0;
      list-style-type: none;
      display: flex;
      justify-content: flex-start;
      align-content: flex-start;
      flex-direction: column;
      row-gap: 20px;

      li {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        p {
          span {
            margin-left: 3px;
            display: inline-block;
            background: $primary;
            color: $white;
            border-radius: 50%;
            width: 20px;
            height: 20px;
            max-width: 20px;
            padding-top: 2px;

            text-align: center;
            vertical-align: middle;
          }
        }
      }
    }

    > .head {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0;

      > .right {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        @include xpay-pre-lg {
          align-items: unset;
        }

        .anticon {
          width: 17px;
          height: 17px;
          margin-left: 8px;

          svg {
            width: 17px;
            height: 17px;

            path {
              fill: $info;
            }
          }
        }

        h4 {
          font-size: 18px;
          width: max-content;
        }

        span {
          color: $gray-span;

          &:not(.anticon) {
            @include xpay-pre-lg {
              display: none;
            }
          }
        }
      }

      .xpay-button {
        margin: 0;

        button {
          border-color: $table-border !important;
          border-radius: 10px;
        }

        @include xpay-pre-lg {
          max-width: 160px;
        }
      }
    }

    .xpay-helper-row {
      padding: 0;
      border: 0;
      margin-bottom: 0;

      .xpay-button {
        button {
          border-radius: 10px;
        }
      }
    }
  }
}
