@import 'styles/references/index';

.signup-code-stage {
  @include xpay-pre-lg {
    width: 100%;
  }

  > h3 {
    @include xpay-pre-lg {
      display: none;
    }
  }

  .signup-code-header {
    text-align: center;
  }

  .signup-code-main {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .signup-code-input {
      direction: ltr;
      > div {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        > input {
          margin: 0 8px;
          border: 1px solid $table-border;
          border-radius: 8px;

          @include xpay-pre-md {
            margin: 0 5px;
          }
        }
      }
    }

    .signup-code-input.has-error {
      input {
        border-color: $danger;
      }
    }

    .signup-code-stats {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin: 10px 0;

      span.resend {
        cursor: pointer;
        color: $info;
      }

      span:last-child {
        min-width: 50px;
      }

      .signup-code-timer {
        align-self: flex-end;
        display: flex;
        justify-content: center;
        align-items: center;

        > div {
          left: auto !important;
          top: auto !important;
          > svg {
            display: none;
          }
          > div {
            position: relative !important;
            height: 30px !important;
            width: 30px !important;
          }
        }
      }
      .signup-code-errors {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        column-gap: 4px;
        color: $danger;
        min-width: 50px;
        font-size: 12px;
      }
    }

    .signup-code-timer {
      align-self: flex-end;
      display: flex;
      justify-content: center;
      align-items: center;

      > div {
        left: auto !important;
        top: auto !important;
        > svg {
          display: none;
        }
        > div {
          position: relative !important;
          height: 30px !important;
          width: 30px !important;
        }
      }
    }
    .signup-code-change-number {
      margin-top: 10px;
      color: $info;
      cursor: pointer;
    }
  }

  .signup-code-button {
    max-width: 100%;
    margin: 0;
    button {
      width: 100%;
    }
  }
}
