@import 'styles/references/index.scss';

.orders-irt-transactions {
  width: 100%;

  .desktop-show {
    @include xpay-pre-lg {
      display: none;
    }

    .xpay-data-table {
      width: 100%;
      padding: 0;
      color: $bold-span;
      border-radius: 10px;

      .rdt_Table {
        border: 1px solid $table-border;

        .rdt_TableHeadRow {
          background: $table-head-bg;
          color: $table-head-text;
        }
      }

      [data-column-id='currencyName'] {
        img {
          width: 26px;
          height: 26px;
          margin-left: 16px;
        }
      }

      [data-column-id='transactionType'] {
        span {
          &.widthrawl {
            color: $danger;
          }

          &.deposit {
            color: $success;
          }
        }
      }

      [data-column-id='date'] {
        .column-date {
          span {
            display: inline-block;
            width: 100%;
          }

          span:last-child {
            font-size: 10px;
            color: $medium-gray;
          }
        }
      }

      [data-column-id='state'] {
        .xpay-badge {
          min-width: 130px;
          display: flex;
          justify-content: flex-start;

          > div {
            height: 30px;
            display: flex;
            justify-content: center;
            flex-direction: row-reverse;

            &.success {
              background: $success-badge;

              img {
                margin-right: 8px;
                order: 2;
              }

              span {
                color: $success;
                order: 1;
              }
            }

            &.error {
              background: $danger-badge;

              img {
                margin-right: 8px;
                order: 2;
              }

              span {
                color: $danger;
                order: 1;
              }
            }

            &.waiting {
              background: $waiting-badge;

              img {
                margin-right: 8px;
                order: 2;
              }

              span {
                color: $warning !important;
                order: 1;
              }
            }
          }
        }
      }

      [data-column-id='detail'] {
        span {
          cursor: pointer;

          svg {
            path {
              fill: $primary;
            }
          }
        }
      }
    }
  }

  .mobile-show {
    display: none;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 100%;

    @include xpay-pre-lg {
      display: flex;
      padding: 0;
      padding-bottom: 50px;
    }

    .xpay-button {
      max-width: unset !important;
    }

    > li {
      background: $white;
      width: 100%;
      padding: 16px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      border: 1px solid $table-border;
      border-left: 0;
      border-right: 0;
      max-width: 425px;

      &:not(li:last-child) {
        border-bottom: 0px;
      }

      padding-bottom: 32px;

      > div {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;

        .right,
        .left {
          display: flex;
          width: max-content;
        }
      }

      > div:first-child {
        .right {
          justify-content: flex-start;
          align-items: center;

          img,
          p,
          span {
            margin-left: 8px;
          }

          img {
            width: 25px;
            height: 25px;
          }

          p {
            font-size: 12px;
            font-weight: 500;
          }

          span {
            color: $gray-text;
            font-size: 12px;
          }

          span:last-child {
            font-family: 'Montserrat';
          }
        }

        .left {
          justify-content: flex-start;
          align-items: center;

          p {
            margin-right: 3px;
          }
        }
      }

      > div:nth-child(2) {
        .left,
        .right {
          p:last-child {
            margin-right: 3px;
          }

          span {
            font-weight: 500;
            margin-right: 3px;
          }
        }
      }

      > div:nth-child(3) {
        .right {
          span {
            margin-right: 3px;
            font-size: 12px;
            color: $gray-text;
          }
        }
      }

      .xpay-button {
        max-width: unset;
        margin: 0;

        button {
          border-color: $table-border !important;
          height: 38px;
        }
      }
    }
  }

  .filter-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;

    @include xpay-pre-lg {
      flex-direction: column;
      background: $white;
    }

    > .ant-divider {
      @include xpay-pre-lg {
        display: none;
      }
    }

    .buttons,
    .fields {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .buttons {
      width: 100%;
      justify-content: flex-end;

      @include xpay-pre-lg {
        justify-content: space-between;
        margin-bottom: 16px;
      }

      > span {
        cursor: pointer;
        width: max-content;
        color: $danger;
        min-width: 60px;
      }

      > .xpay-button {
        min-width: unset;
        width: 100%;
        margin: 0 0 0 16px;

        @include xpay-pre-lg {
          margin: 0 auto;
        }

        &:last-child {
          margin-right: 16px;

          @include xpay-pre-lg {
            margin-right: 0;
          }

          button {
            background: $danger-badge;
            color: $danger;
            border: 1px solid $danger;
          }
        }

        button {
          font-weight: 500;
        }
      }
    }

    .fields {
      width: 100%;
      @include xpay-pre-lg {
        flex-direction: column;
      }

      input {
        height: 38px !important;
      }

      > div {
        min-width: 130px;
        width: 100%;
        color: $info;
        border-radius: 10px;

        &.transaction-state {
          min-width: 200px;
        }

        @include xpay-pre-lg {
          margin-left: 0;
        }

        &:last-child:not(.date) {
          margin-right: 16px;

          img {
            width: 25px;
            height: 25px;
            margin-left: 8px;
          }
        }

        &.states-dropdown {
          min-width: max-content;
        }
      }

      &:first-child {
        @include xpay-pre-lg {
          margin-top: 16px;
        }
      }

      .ant-dropdown-trigger {
        button {
          border-color: $table-border !important;

          > span:first-child {
            color: rgb(117, 117, 117);
          }
        }

        &.ant-dropdown-open,
        &:hover {
          button > span:first-child {
            color: $white;
          }
        }
      }

      .xpay-calendar {
        cursor: pointer;

        .xpay-input {
          .input-field {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 16px;
            background: $white;

            input {
              cursor: pointer;
            }

            svg {
              path {
                fill: $primary;
              }
            }
          }
        }
      }
    }
  }
}
