@import 'styles/references/index.scss';

.xpay-helper-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 80px;
  background: $white;
  padding: 15px;
  border-radius: 20px;
  border: 1px solid $info;
  margin: 24px auto;
  animation: xpay-fade-in 1.4s;
  transition: 0.8s;

  &.expanded {
    transition: 0.8s;
    height: max-content;
  }
  img {
    width: 240px;
    margin-bottom: 24px;
  }
  @include xpay-pre-lg {
    flex-direction: column;
    height: max-content;
  }

  &.desktop-show {
    .xpay-button {
      text-align: -webkit-left;

      button {
        max-width: 200px;
      }
    }
    @include xpay-pre-lg {
      display: none;
    }
  }

  &.mobile-show {
    display: none;
    flex-direction: row;
    justify-content: center;

    @include xpay-pre-lg {
      display: flex;
    }

    .right-side {
      display: none;
    }

    .xpay-button {
      display: flex;
      justify-content: center;
    }
  }

  .helper-footer {
    display: flex;
    align-items: center;
    width: unset;
    column-gap: 16px;
    @include xpay-pre-lg {
      width: 100%;
    }
  }
  .right-side {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
    width: 60%;
    @include xpay-pre-lg {
      width: 100%;
      text-align: center;
      justify-content: center;

      p {
        width: 34ch;
        margin-bottom: 16px;
      }
    }
    svg {
      @include xpay-pre-lg {
        display: none;
      }
    }
    .text {
      font-size: 16px;
      width: 100%;

      h4 {
        font-weight: 500;
        color: $info;
      }
      span {
        width: max-content;
      }
    }
  }
  .help-content {
    margin: 24px auto;
    transition: 0.6s;

    ul {
      width: 100%;
      margin: 0;
      padding: 0;
      list-style-type: none;
      display: flex;
      justify-content: flex-start;
      align-content: flex-start;
      flex-direction: column;
      row-gap: 20px;

      li {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        p {
          span {
            margin-left: 3px;
            display: inline-block;
            background: $primary;
            color: $white;
            border-radius: 50%;
            min-width: 20px;
            max-height: 20px;
            max-width: 20px;

            text-align: center;
          }
        }
      }
    }
  }
  .xpay-button {
    max-width: unset !important;
  }
  .helper-footer .xpay-button:first-child {
    display: none;
    @include xpay-pre-lg {
      display: block;
    }
  }
  .xpay-button button {
    height: 48px !important;
  }
  .xpay-button.watch-video {
    width: 180px;
    border-radius: 10px;
    margin: 0;
    height: 48px;

    @include xpay-pre-lg {
      max-width: unset;
      margin: 0;
      width: 100%;
    }
    button {
      background: $primary;
      column-gap: 5px;
      img {
        order: 1;
        transform: rotate(-180deg);
      }
      span {
        order: 2;
      }
    }
  }
}

.xpay-help-modal {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 328px;
  background: white;
  max-width: 328px;
  text-align: center;
  border-radius: 16px;
  padding: 16px;

  .modal-title,
  .modal-description {
    margin-bottom: 24px;
  }
  .player {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 18px auto;
    flex-direction: column;
    row-gap: 25px;

    img {
      height: 245px;
    }
    div {
      width: 100% !important;
      height: auto !important;
      video {
        border-radius: 16px;
        height: max-content !important;
        text-align: center;
        max-width: 640px;
      }
    }
  }
  p {
    text-align: right;
    padding: 8px;
  }
  .xpay-button {
    width: 100%;
    max-width: unset;
  }
}
