@import "styles/references/index.scss";

.orders-detail-modal {
  .detail-card {
    @include xpay-pre-lg {
      top: 20px;
      top: calc(50% - 330px);
      left: calc(50% - 40px);

      overflow-y: hidden !important;
    }
  }
}

.xpay-my-orders {
  border-radius: 20px;
  padding: 24px;
  background: $white;

  @include xpay-pre-lg {
    margin-top: 0;
  }

  .type-selection {
    padding: 24px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include xpay-pre-lg {
      display: none !important;
    }

    > div {
      width: 100%;
      color: $gray-text;
      border-bottom: 1px solid $gray-text;
      text-align: center;
      padding: 16px;
      cursor: pointer;
      transition: 0.5s;

      &.active {
        color: $info;
        border-bottom: 2px solid $info;
      }

      span {
      }
    }
  }

  .orders-table {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    .xpay-data-table {
      width: auto !important;
    }

    .pagination-footer {
      width: 100%;
      min-height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;

      > .anticon {
        cursor: pointer;
        margin-right: 10px;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        border: 1px solid $table-border;
        height: 40px;
        width: 40px;
        color: $info;
        transition: 0.4s;

        &:hover {
          background: $primary;
          color: $white;
          transition: 0.4s;
        }
      }

      .pages {
        display: flex;
        justify-content: center;
        align-items: center;

        .xpay-button {
          margin-right: 10px;
          button {
            height: 40px;
            width: 40px;
            padding: 10px;
            border-radius: 6px;
            border: 1px solid $table-border !important;

            &.info {
              background: linear-gradient(95.43deg, $primary -25.7%, $access 112.1%);
            }
          }
        }
      }
    }
  }
}
