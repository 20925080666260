@import 'src/styles/references/index';

.bottom-menu-items {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  list-style-type: none;
  padding: 0;
  margin: 0;
  color: $gray-span;
  transition: 0.5s;

  a.bottom-menu-item {
    color: $gray-span;
    width: 20%;
    height: 100%;
    display: flex;
    justify-content: end;
    margin-bottom: 6px;
    align-items: center;
    flex-direction: column;
    row-gap: 10px;
    transition: 0.5s;

    .bottom-menu-icon {
      svg {
        vertical-align: middle;
        transition: 0.5s;
      }
    }

    &:first-child {
      &.menu-button-active {
        > span {
          color: $info;
        }
        .bottom-menu-icon {
          svg {
            path {
              fill: $info;
            }
          }
        }
      }
    }

    &.active {
      .bottom-menu-icon {
        background: $primary;
        padding: 20px;
        border-radius: 20px;
        margin-top: -40px;
        transition: 0.5s;
        -webkit-transition: 0.5s;
        -moz-transition: 0.5s;

        svg {
          path {
            fill: $white;
            transition: ease-in-out 0.5s;
          }
        }
      }

      &:nth-child(4) {
        .bottom-menu-icon {
          svg {
            rect {
              fill: $gray-span;
            }
            path {
              fill: $white;
            }
          }
        }
      }
      &:nth-child(5) {
        .bottom-menu-icon {
          svg {
            rect {
              fill: $white;
            }
            path {
              fill: $gray-span;
            }
          }
        }
      }
    }
  }

  a.bottom-menu-item:nth-child(3) {
    &.active {
      svg {
        path {
          fill: $white;
        }

        rect {
          fill: $white;
        }
      }
    }
  }
}
