@import "styles/references/index.scss";

.xpay-two-step-verify {
  display: flex;
  width: auto;
  height: auto;
  min-height: 340px;
  max-width: 430px;
  border-radius: 20px;
  padding: 32px;
  overflow-y: visible !important;
  justify-content: space-around !important;

  .card-body {
    margin: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    .xpay-button {
      margin-top: 32px;
      width: 100%;
      max-width: unset;

      button.info {
        background: $primary;
        font-weight: 500;
        font-size: 16px;
      }
    }

    .head {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: column;
      margin: 0;

      > div:first-child {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        h4 {
          font-size: 26px;
          font-weight: 500;
        }

        > span {
          font-size: 30px;
          font-weight: 700;
        }

        svg {
          cursor: pointer;
        }
      }

      > p {
        margin-top: 15px;
        font-size: 14px;
        color: $bold-span;
        max-width: 90%;
      }
    }

    .buttons {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .xpay-button {
        width: calc(50% - 8px);

        button {
          height: 48px !important;
        }
      }
    }

    .main {
      width: 100%;
      margin-top: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }
}
