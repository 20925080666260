@import 'src/styles/references/index.scss';

.sidebar-main {
  &.mobile-show {
  }

  > .sidebar-item {
    position: relative;
    margin-right: 0px;
    padding: 0px 24px;
    height: 48px;
    width: 227px;
    max-width: 240px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;

    justify-content: flex-start;

    @include xpay-pre-lg {
      max-width: unset;
      width: 100%;
      justify-content: space-between;
    }

    &.back-button {
      justify-content: space-between;
      margin-bottom: 24px;
      border: 1px solid $gray-span;

      .content {
        order: 2;
      }

      .content .title {
      }

      > svg {
        transform: rotate(180deg);

        path {
          order: 1;
        }
      }
    }

    .content svg {
      width: 21px !important;
    }

    .content .anticon svg {
      fill: $gray-span;
    }

    &.active {
      justify-content: space-between;
      background: $primary;

      .content svg path,
      .content svg {
        fill: $white;
      }

      .title {
        color: $white;
      }

      &.filled {
        .content {
          svg {
            rect {
              fill: $white;
            }

            path {
              fill: $gray-span;
            }
          }
        }
      }
    }

    .content {
      display: flex;
      flex-wrap: nowrap;
    }

    .icon {
      width: 20px;
      height: 20px;
    }

    .title {
      margin-right: 13px;
      color: $gray-span;
      font-size: 1rem;
      font-weight: 300;
      letter-spacing: 0em;
      width: max-content;
      padding-top: 4px;
    }

    > svg {
      display: none;
      margin-right: 12px;

      @include xpay-pre-lg {
        display: block;
      }

      > path {
        @include xpay-pre-lg {
          fill: $gray-span;
        }
      }

      &.active {
        display: block;

        path {
          fill: $white;
        }
      }
    }
  }
}
