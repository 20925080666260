@import 'styles/references/index.scss';

.xpay-dashboard-referral {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 100px;

  @include xpay-pre-lg {
    margin-bottom: 120px;
    padding: 0 0;
  }

  > .xpay-helper-row,
  > .xpay-panel-card:not(:first-child) {
    margin: 24px auto 0;
    @include xpay-pre-lg {
      width: calc(100% - 48px);
      margin: 32px auto 0;
    }
  }

  .guide-card {
    width: 100%;
    border-radius: 20px;
    width: 100%;

    @include xpay-pre-lg {
      background: transparent;
      padding: 0;
    }

    > .card-body {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      margin: 0;

      .buttons {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 32px;
      }

      .ads-pic {
        width: 100%;
      }

      .banner {
        width: 100%;
        margin-top: 23px;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 24px;
        border-radius: 10px;
        overflow-y: hidden;
        height: 300px;
        border: 1px solid $table-border;
        border-radius: 20px;
        background-color: $white;

        @include xpay-pre-lg {
          height: 350px;
          padding: 32px 16px 24px;
          justify-content: flex-start;
          height: max-content;
          border-radius: 10px;
        }

        > .overlay {
          position: absolute;
          width: 50%;
          height: 100%;
          top: 0;
          right: 0;
          background: url('./../../../../public/assets/images/slides/mask.svg');
          background-repeat: no-repeat;
          background-size: cover;
          z-index: 0;

          @include xpay-pre-lg {
            width: 100%;
          }
        }

        > img {
          position: absolute;
          width: 50%;
          height: auto;
          top: 0;
          left: 0;
          z-index: 0;

          @include xpay-pre-lg {
            display: none;
          }
        }

        > .content {
          position: relative;
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: column;
          width: 100%;
          height: 300px;
          padding: 24px 0;
          z-index: 1;

          @include xpay-pre-lg {
            justify-content: flex-start;
            padding: 0;
            height: auto;
          }

          @include xpay-pre-lg {
            width: 100%;
          }

          > div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            margin-bottom: 32px;

            @include xpay-pre-lg {
              margin-bottom: 16px;
            }

            &.buttons {
              margin-top: 0;
              margin-bottom: 0;
            }
          }

          > .invite-link {
            border-radius: 10px;
            background: $white;
            border: 1px solid $table-border;
            padding: 24px;
            height: 56px;

            @include xpay-pre-lg {
              border-radius: 5px;
              padding: 16px 8px;
            }

            a {
              font-size: 16px;
              font-weight: 500;
              color: $info;

              @include xpay-pre-lg {
                font-size: 12px;
              }
            }

            .anticon {
              color: $info;
            }

            p {
              font-family: monospace !important;
              text-align: center;
              width: 65%;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow-x: hidden;
              direction: ltr;

              @include xpay-pre-lg {
                font-size: 13px;
                font-weight: 700;
              }
            }
          }

          .xpay-button {
            max-width: unset;
            margin: 0;
            direction: ltr;

            button {
              height: 56px;

              @include xpay-pre-lg {
                font-weight: 500;
              }

              &.info {
                background: linear-gradient(
                                95.43deg,
                                $info -25.7%,
                                $access 112.1%
                ) !important;
              }
            }

            &:first-child {
              margin-left: 24px;

              @include xpay-pre-lg {
                margin-left: 16px;
              }
            }
          }
        }
      }

      .prize-status {
        position: relative;
        width: 100%;
        height: 80px;
        border: 1px solid $table-border;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 20px;
        padding: 24px;
        margin-top: 24px;

        @include xpay-pre-lg {
          flex-direction: column;
          height: max-content;
          background: $white;
        }

        > .col {
          display: flex;
          z-index: 1;
          justify-content: space-between;
          align-items: center;
          width: 333px;
          background: $white;
          border: 1px solid $table-border;
          padding: 10px;
          border-radius: 10px;

          @include xpay-pre-lg {
            width: 100%;
          }

          &:not(.col:last-child) {
            @include xpay-pre-lg {
              margin-bottom: 16px;
            }
          }

          p {
            font-size: 18px;
            font-weight: 500;
          }

          span {
            font-size: 14px;
            margin-right: 10px;
          }
        }
      }

      .banner,
      .prize-status {
        @include xpay-pre-lg {
          width: calc(100% - 48px);
          margin: 32px auto 0;
        }
      }

      .steps-row {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 20px;

        @include xpay-pre-lg {
          flex-direction: column;
          margin-top: 32px;
        }

        .desktop-show {
          display: flex;
          justify-content: center;
          flex-direction: column;
          width: 70%;

          @include xpay-pre-lg {
            display: none;
          }

          .guide {
            position: relative;
            height: 290px;
            width: 100%;
            border: 1px solid $table-border;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: column;
            border-radius: 20px;
            padding: 24px;
            margin-top: 24px;

            @include xpay-pre-lg {
              width: 100%;
              margin-top: 0;
              padding-top: 0;
            }

            > p {
              width: 100%;
              font-size: 20px;
              font-weight: 500;
              text-align: center;
            }

            .steps {
              margin-top: 24px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
              padding: 0 15%;

              p {
                display: flex;
                justify-content: center;
                align-items: center;
                background: $primary;

                color: $white;
                border-radius: 50px;
                width: 28px;
                height: 28px;
                font-size: 18px;
                font-weight: 500;
              }
            }

            .text {
              margin-top: 24px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
              text-align: center;

              .col {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                max-width: 30%;

                span {
                  font-size: 18px;
                  font-weight: 500;
                }
              }
            }
          }
        }

        .mobile-show {
          display: none;
          width: 100%;
          position: relative;

          @include xpay-pre-lg {
            display: block;
          }

          .next-navigation {
            background: linear-gradient(95.43deg, $info -25.7%, $access 112.1%);
            backdrop-filter: blur(30px);
            position: absolute;
            width: 24px;
            height: 24px;
            border-radius: 10px;
            left: 16px;
            top: calc(16% - 0px);
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 100;

            @include xpay-pre-lg {
              left: 10px;
            }
          }

          .pre-navigation {
            background: linear-gradient(95.43deg, $info -25.7%, $access 112.1%);
            backdrop-filter: blur(30px);
            position: absolute;
            width: 24px;
            height: 24px;
            border-radius: 10px;
            cursor: pointer;
            right: 16px;
            top: calc(16% - 0px);
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 100;
            transform: rotate(180deg);

            @include xpay-pre-lg {
              right: 10px;
            }
          }

          .swiper-pagination-bullets {
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;

            .swiper-pagination-bullet {
              width: 8px;
              height: 8px;
              background: $primary;
              opacity: 0.5;
              border-radius: 30px;
              display: flex;
              justify-content: center;
              align-items: center;

              &.swiper-pagination-bullet-active {
                width: 16px;
                height: 16px;
                opacity: 1;
                background: $primary;

                &::before {
                  content: '';
                  display: block;
                  width: 8px;
                  height: 8px;
                  background: $white;
                  border-radius: 30px;
                }
              }
            }
          }

          .swiper {
            width: calc(100% - 48px);
          }

          .guide {
            position: relative;
            width: 70%;
            border: 1px solid $table-border;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: column;
            border-radius: 20px;
            padding: 24px;
            margin-top: 24px;
            background: $white;
            height: 259px;

            @include xpay-pre-lg {
              width: 100%;
              padding-bottom: 56px;
              margin-top: 0;
            }

            > p {
              width: 100%;
              font-size: 20px;
              font-weight: 500;
              text-align: center;
            }

            .steps {
              margin-top: 24px;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              padding: 0 15%;

              p {
                display: flex;
                justify-content: center;
                align-items: center;
                background: $primary;

                color: $white;
                border-radius: 50px;
                width: 28px;
                height: 28px;
                font-size: 18px;
                font-weight: 500;
              }
            }

            .text {
              margin-top: 24px;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              text-align: center;

              @include xpay-pre-lg {
                margin-top: 16px;
              }

              .col {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                span {
                  font-size: 18px;
                  font-weight: 500;
                }

                p {
                  margin-top: 8px;
                }
              }
            }
          }
        }

        .balance {
          align-self: flex-start;
          position: relative;
          width: 30%;
          border: 1px solid $table-border;
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: column;
          border-radius: 20px;
          padding: 24px;
          margin-top: 24px;
          margin-right: 24px;

          @include xpay-pre-lg {
            width: 100%;
            margin-right: 0px;
            background: $white;
          }

          .row {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: column;
            margin-top: 24px;

            &:first-child {
              margin-top: 0;
            }

            > p {
              width: 100%;
              font-size: 14px;
              font-weight: 500;
              text-align: center;
            }

            > div {
              display: flex;
              justify-content: start;
              align-items: center;
              width: 100%;
              border: 1px solid $table-border;
              height: 68px;
              border-radius: 10px;
              padding: 16px;
              margin-top: 8px;

              .icon {
                width: 36px;
                height: 36px;
                padding: 8px;
                background-color: $table-head-bg-2;
                display: flex;
                border-radius: 5px;

                svg {
                  margin: auto;
                }
              }

              span {
                justify-self: center;
                margin: auto;
                font-weight: 500;
              }

              span:last-child {
                width: 36px;
                margin: 0;
                height: 36px;
              }
            }
          }

          > .xpay-button {
            max-width: unset;
            margin: 0;
            margin-top: 24px;

            button {
              height: 48px;
              background: linear-gradient(
                              95.43deg,
                              $info -25.7%,
                              $access 112.1%
              ) !important;
            }
          }
        }

        .balance {
          @include xpay-pre-lg {
            width: calc(100% - 48px);
            margin: 32px auto 0;
          }
        }
      }
    }
  }

  .xpay-panel-card {
    margin-bottom: 0;
  }

  .referral-table-card {
    border-radius: 20px;
    margin-bottom: 0;

    @include xpay-pre-lg {
      padding-left: 0px;
      padding-right: 0px;
    }

    .card-body {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: column;
      margin: 0;

      > .head {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0;

        @include xpay-pre-lg {
          padding: 0 16px;
        }

        > .right {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .anticon {
            width: 17px;
            height: 17px;
            margin-left: 20px;

            svg {
              path {
                fill: $primary;
              }
            }
          }

          h4 {
            font-size: 16px;
            margin-left: 3px;
            width: max-content;
          }

          span {
            color: $gray-span;

            &:not(.anticon) {
              @include xpay-pre-lg {
                display: none;
              }
            }
          }
        }

        > .left {
          display: flex;
          justify-content: flex-end;
          align-items: center;

          > span {
            color: $danger;
            min-width: max-content;
          }

          .xpay-button {
            direction: ltr;
            margin-right: 16px;
            max-width: unset;

            button {
              height: 40px;
              background: linear-gradient(
                              95.43deg,
                              $info -25.7%,
                              $access 112.1%
              );
            }
          }
        }

        .xpay-button {
          margin: 0;

          @include xpay-pre-lg {
            max-width: 100px;
          }
        }
      }

      > .desktop-show {
        width: 100%;

        @include xpay-pre-lg {
          display: none;
        }

        .xpay-data-table {
          margin-right: 0;
          margin-left: 0;
          border: 1px solid $table-border;
          border-radius: 10px;
          width: 100%;

          > header {
            display: none;
          }

          > div:nth-child(2) {
            border-radius: 10px;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-bottom: 1px solid $table-border;
            border-top: 1px solid $table-border;
          }

          > div:last-child {
          }

          .rdt_TableHeadRow {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            color: $table-head-text;
            background: $table-head-bg;
          }

          .rdt_TableCell {
            color: $bold-span;
          }
        }
      }

      > .mobile-show {
        display: none;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        width: 100%;

        @include xpay-pre-lg {
          display: flex;
          padding: 0;
          padding-top: 24px;
        }

        > .search {
          padding: 16px 16px 0;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          .xpay-input {
            margin-bottom: 16px;
            width: 100%;
            max-width: unset;

            .input-field {
              border-radius: 5px;
              background: $white;
              display: flex;
              justify-content: space-between;
              align-items: center;

              .anticon {
                margin: 12px 14px;

                svg {
                  path {
                    fill: $primary;
                  }
                }
              }
            }
          }
        }

        > li {
          background: $white;
          width: 100%;
          padding: 16px;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-direction: column;
          border: 1px solid $table-border;
          border-left: 0;
          border-right: 0;
          max-width: unset;

          &:not(li:last-child) {
            border-bottom: 0px;
          }

          > .row {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 16px;

            .right,
            .left {
              display: flex;
              width: max-content;
            }
          }

          .xpay-button {
            max-width: unset;
            margin: 0;

            button {
              border-color: $table-border !important;
              height: 38px;
            }
          }
        }
      }
    }
  }
}

.xpay-modal.referral-invitation-modal {
  max-width: unset;
  min-width: unset;

  .modal-content {
    border-radius: 20px;
    width: 420px;

    .head {
      margin-bottom: 24px;
    }

    > .description {
      display: none;
    }

    .content-description {
      border-radius: 10px;
      background-color: $d9Gray;
      padding: 24px;
      border: 1px $gray-text solid;

      p {
        width: 100%;
        text-align: right;
        margin: 0 auto;
        font-size: 10px;
        color: $bold-span;
      }
    }

    .buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .xpay-button:first-child {
        margin-right: 0px;
      }

      margin-top: 24px;

      .xpay-button {
        margin-right: 0;

        &:last-child {
          margin-right: 16px;
        }

        button {
          height: 48px;

          &.info {
            background: linear-gradient(
                            95.43deg,
                            $primary -25.7%,
                            $access 112.1%
            );
          }

        }
      }

    }
  }
}

.referral-withdrawal-modal {


  .referral-withdrawal {
    display: flex;
    width: auto;
    height: auto;
    max-width: 430px;
    border-radius: 20px;
    padding: 32px;
    overflow-y: visible !important;

    .input-info-label {
      width: 100%;

      margin-bottom: 3px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .card-body {
      margin: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;

      > .xpay-button {
        margin-top: 24px;
      }
    }
  }
}

.xpay-modal.referral-ads-modal {
  max-width: unset;
  min-width: unset;

  .modal-content {
    border-radius: 20px;
    width: 420px;

    > .description {
      display: none;
    }

    > .head {
      margin-bottom: 24px;

      .close {
        cursor: pointer;
      }
    }

    > .invite-link {
      margin-top: 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 24px;
      border-radius: 10px;
      background: $white;
      border: 1px solid $table-border;
      padding: 16px 24px;
      height: 56px;

      @include xpay-pre-lg {
        border-radius: 5px;
        padding: 16px 8px;
      }

      a {
        font-size: 12px;
        font-weight: 500;
        color: $info;
        width: max-content;

        @include xpay-pre-lg {
          font-size: 10px;
        }
      }

      .anticon {
        color: $info;
      }

      p {
        font-family: monospace !important;
        text-align: center;
        width: 200px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow-x: hidden;
        direction: ltr;
        font-size: 10px;

        @include xpay-pre-lg {
          font-size: 10px;
          font-weight: 700;
          width: 150px;
        }
      }
    }

    .ad-image {
      position: relative;

      .ant-segmented {
        overflow: hidden;
        position: absolute;
        top: 16px;
        left: calc(50% - 55px);

        .ant-segmented-item {
          color: $primary;
        }

        .ant-segmented-item-selected {
          background: linear-gradient(
                          95.43deg,
                          $primary -25.7%,
                          $access 112.1%
          );
          color: $black;
        }
      }

      > img {
        border-radius: 10px;
        width: 100%;

        @include xpay-pre-lg {
          width: 100%;
        }
      }
    }

    .buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;

      a {
        width: 50%;
        display: flex;

        .xpay-button {
          margin-right: 0px;
          width: 100%;
          margin: 0;


          button {
            width: 100%;
          }
        }
      }

      > .xpay-button:first-child {
        margin-right: 0px;
      }

      margin-top: 24px;

      > .xpay-button {
        margin-right: 0;

        &:last-child {
          margin-right: 16px;
          width: 50%;
        }

        button {
          height: 48px;

          &.info {
            background: linear-gradient(
                            95.43deg,
                            $primary -25.7%,
                            $access 112.1%
            );
          }
        }
      }
    }
  }
}
