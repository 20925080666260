@import 'src/styles/references/index';

.xpay-panel-card {
  width: 100%;
  height: 100%;
  background: $white;
  padding: 24px;
  border-radius: 8px;
  overflow-y: scroll !important;
  scrollbar-width: none;

  &.shadowed {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }

  display: flex;
  justify-content: start;
  flex-direction: column;

  .head {
    display: flex;
    justify-content: space-between;
    align-content: center;
    margin-bottom: 24px;

    img {
      height: 34px;
    }

    h3 {
      font-size: 16px;
      color: $black;
    }
  }

  .card-body {
    width: 95%;
    margin: 24px auto;
    height: 100%;
    position: relative;

    .ant-spin-nested-loading {
      width: 100%;
    }
  }
  &.detail-card::-webkit-scrollbar {
    width: 0px;
  }
}
