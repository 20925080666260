@import 'styles/references/index.scss';

.xpay-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  z-index: 1020;
  padding: 32px 16px;
  min-width: 412px;

  @include xpay-pre-lg {
    min-width: 324px;
  }

  .xpay-button {
    max-width: unset !important;
  }

  .xpay-panel-card {
    padding: 0px !important;
  }

  .modal-content {
    background: white;
    padding: 24px;
    border-radius: 8px;

    @include xpay-pre-lg {
      max-width: 100% !important;
    }

    .head {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: $white;
      margin-bottom: 32px;

      .close {
        display: flex;
        align-items: center;
      }

      h4 {
        text-align: center;
        font-size: 18px;
        font-weight: 500;
      }
    }

    .xpay-input {
      width: 100%;
    }

    .description {
      p {
        width: 100%;
        text-align: center;
        margin: 0 auto;
        color: $gray-span;
      }
    }

    .confirm-box {
      .buttons {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 32px;

        .xpay-button:first-child {
          margin-right: 0px;
        }

        .xpay-button {
          max-width: 130px;

          &:last-child {
            margin-right: 16px;
          }

          button {
            height: 40px;

            &.outlined {
              border: 1px solid $gray-span !important;
              color: $gray-span;

              &:hover {
                background: transparent;
              }
            }
          }
        }
      }
    }
  }
}
