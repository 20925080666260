@import 'src/styles/references/index.scss';

.xpay-modal.logout-modal {
  .modal-content {
    > .head {
      display: none;
    }

    .confirm-box {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      min-height: 100px;
      min-width: max-content;

      > .head {
        justify-content: center;
      }
    }
  }
}

.xpay-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  padding-top: 32px;
  column-gap: 32px;
  z-index: 1000;

  @include xpay-pre-lg {
    padding: 20px 24px 8px;
    background-color: $bg;
    left: 0;
    top: 0;
    width: 100%;
    margin: 0px auto;
    z-index: 999;
  }

  .box {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $white;
    padding: 16px;
    width: 80vw;
    margin: auto auto;
    border-radius: 20px;
  }

  .space {
    min-width: 255px;
  }

  .navbar-welcome {
    @include xpay-pre-lg {
      display: none;
    }

    h4 {
      margin-bottom: 0;
    }
  }

  .navbar-date {
    @include xpay-pre-lg {
      display: none;
    }
    span {
      color: $gray-span;
    }
  }

  .navbar-user {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 20px;
    padding-left: 20px;

    @include xpay-pre-lg {
      display: flex !important;
      padding-left: 0;
      width: 100%;
      justify-content: space-between;
    }

    .mobile-leftside {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 10px;

      @include xpay-pre-lg {
        order: 2;
      }

      > .mobile-back-btn {
        display: none;
        width: 30px;
        height: 30px;
        order: 2;
        cursor: pointer;

        @include xpay-pre-lg {
          display: block;
        }
      }

      > svg {
        display: none;
        @include xpay-pre-lg {
          display: block;
        }
      }

      .navbar-search {
        display: flex;
        justify-content: center;
        align-items: center;
        justify-self: flex;
        display: none;
        background: $white;
        width: 30px;
        height: 30px;
        border-radius: 10px;
        order: 2;

        @include xpay-pre-lg {
          display: flex;
        }

        img {
          vertical-align: middle;
          margin: auto;
          width: 30px;
          height: 30px;

          @include xpay-pre-lg {
            width: 15px;
            height: 15px;
          }
        }
      }
    }

    .navbar-profile {
      position: relative;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: 10px;

      @include xpay-pre-lg {
        order: 1;
        justify-content: flex-start;
      }
    }

    .navbar-profile > .dropdown {
      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: 10px;
      @include xpay-pre-lg {
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
      }

      > span {
        @include xpay-pre-lg {
          font-weight: 500;
        }
      }

      > h4 {
        display: none;
        @include xpay-pre-lg {
          display: block;
          font-size: 14px;
          color: $gray-span;
        }
      }
      p.userinfo {
        margin: 0;
      }
      svg {
        @include xpay-pre-lg {
          display: none;
        }
      }

      .dropdown-body {
        position: absolute;
        top: 48px;
        left: -5px;
        background: $white;
        border-radius: 10px;
        width: 150px;
        height: max-content;
        padding: 5px;
        cursor: pointer;
        transform: 0.5s;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        filter: drop-shadow(0px 100.058px 80.0461px rgba(93, 128, 248, 0.05))
          drop-shadow(0px 64.8521px 46.8788px rgba(93, 128, 248, 0.037963))
          drop-shadow(0px 38.5407px 25.4961px rgba(93, 128, 248, 0.0303704))
          drop-shadow(0px 20.0115px 13.0075px rgba(93, 128, 248, 0.025))
          drop-shadow(0px 8.15284px 6.52227px rgba(93, 128, 248, 0.0196296))
          drop-shadow(0px 1.85292px 3.14996px rgba(93, 128, 248, 0.012037));

        @include xpay-pre-lg {
          left: -20px;
        }

        > svg {
          position: absolute;
          top: -7px;
          right: 5px;
        }

        span.divider {
          position: relative;
          left: 5px;
          display: inline-block;
          width: calc(100% + 10px);
          height: 1px;
          background: $table-border;
          margin: 1px 0;
        }

        .item:hover {
          background: $symbol-btn-bg;
          transition: 0.5s;
        }

        a.item {
          color: $bold-span !important;
        }

        .item {
          padding: 5px;
          border-radius: 5px;
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin: 3px 0 0 0;

          &:last-child {
            margin-bottom: 3px;
          }

          svg {
            width: 16px;
            height: 16px;
          }

          span:last-child {
            margin-right: 5px;
            display: unset;
          }
        }
      }
    }

    .navbar-profile > .avatar {
      background: $primary;
      border-radius: 5px;

      > img {
        width: 40px;
        height: 40px;
      }
    }
  }
}
