@import 'src/styles/references/index.scss';

.in-deposit-page {
  margin-bottom: 100px;
}

.xpay-deposit-page {
  width: 100%;
  height: max-content;
  column-gap: 5px;
  background: $white;
  border-radius: 20px;
  overflow: hidden;
  margin-bottom: 8px;

  @include xpay-pre-lg {
    width: 100%;
    background: $bg;
  }
  @include xpay-pre-md {
    width: 100%;
    padding: 0 24px;
    margin: 0 auto;
  }

  @include xpay-pre-lg {
    .ant-col {
      margin-top: 32px;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  .right-side {
    padding: 32px;

    @include xpay-pre-lg {
      padding: 0px;
      width: 95%;
    }
    .xpay-transaction-box {
      margin: 0 auto;
      border: 0px !important;

      input {
        @include xpay-pre-lg {
          font-size: 16px;
        }
      }
      &.sell {
        .calculator .field:first-child .symbol {
          border-radius: 10px;
        }
      }
      &.buy {
        .calculator .field:nth-child(2) .symbol {
          border-radius: 10px;
        }
      }
      .xpay-alert {
        margin-top: 32px !important;
      }
      .calculator {
        margin-top: 24px;
        .field {
          .symbol {
            width: 45%;
          }
        }
      }
    }
  }

  .xpay-alert.warning.icon-on-top {
    margin: 32px 0;
    .alert-content {
      width: 100%;
      max-width: unset;
      .message {
        width: 100%;
        text-align: center;
        font-size: 14px;
      }
    }
  }

  .desktop-show {
    @include xpay-pre-lg {
      display: none;
    }
  }

  .mobile-show {
    display: none;
    @include xpay-pre-lg {
      display: unset;
    }
  }

  .left-side {
    background: $deposit-shadow;
    box-shadow: 0px 0px 20px 0px rgb(19 42 43 / 8%);

    @include xpay-pre-lg {
      box-shadow: unset;
      border-radius: 10px;

      .xpay-helper-card {
        border-radius: 10px !important;
      }
    }
  }
}
.help-bar-row {
  @include xpay-pre-lg {
    margin: 16px auto;
    width: 90%;
  }
}
