@import 'styles/references/index';

.twofactor-stage {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 350px;
  row-gap: 30px;

  > h3 {
    @include xpay-pre-lg {
      display: none;
    }
  }

  form {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 30px;

    .input {
      direction: ltr;
      width: auto !important;
      > div {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        > input {
          margin: 0 10px;
          border: 1px solid $table-border;
          border-radius: 10px;
          max-height: 48px;

          @include xpay-pre-md {
            margin: 0 5px;
          }
        }
      }
    }

    .signin-remember {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      column-gap: 4px;
    }

    .signin-options {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        color: $info;
        cursor: pointer;
      }
    }

    .signin-button {
      max-width: 100%;
      margin: 0;
      button {
        width: 100%;
      }
    }

    .signup-link {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      > div {
        display: flex;
        width: max-content;
        a {
          font-weight: 500;
          margin-right: 5px;
        }
      }
    }
  }
}
