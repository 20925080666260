@import "src/styles/references/index.scss";

.xpay-uusd-thankyou-dashboard {
  width: 100% !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  flex-direction: column;
  row-gap: 5px !important;
  border-radius: 20px !important;
  padding: 24px !important;
  height: auto;
  @include xpay-pre-lg {
    width: 100%;
    background: $bg;
  }
  @include xpay-pre-md {
    width: 100%;
    padding: 0;
    margin: 0 auto;
  }

  .transaction-info {
    border-radius: 20px;
    padding: 0;

    .card-body {
      width: 100%;
      padding: 0 0 32px;
      margin: unset !important;
    }

    .header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding-right: 24px;
      padding-bottom: 32px;
      border-bottom: 2px solid $ant-form-bg;
      flex-wrap: wrap;

      background-image: url("./../../../../../../public/assets/images/thankyou/sphere.svg");
      background-repeat: no-repeat;
      background-position: left;

      @include xpay-pre-lg {
        padding-right: 12px;
        padding-left: 12px;
        padding-bottom: 16px;
      }

      .left-side {
        @include xpay-pre-lg {
          width: 100%;
          order: 1;
        }

        img {
          @include xpay-pre-lg {
            width: 100%;
          }
        }
      }

      .right-side {
        @include xpay-pre-lg {
          order: 2;
        }

        h3 {
          padding-top: 40px;
          font-size: 24px;
          margin-bottom: 24px;
        }

        p {
          max-width: 58ch;
        }
      }

      &.danger {
        background-image: url("./../../../../../../public/assets/images/thankyou/danger-sphere.svg");
        background-repeat: no-repeat;
        background-position: left;
      }

      h3 {
        padding-top: 40px;
        font-size: 32px;
        margin-bottom: 24px;
      }

      p {
        max-width: 58ch;
      }
    }

    ul {
      padding: 0px;
      margin: 0px;
      display: flex;
      list-style: none;
      column-gap: 52px;
      flex-wrap: wrap;
      margin: 24px 0;
      column-gap: 52px;
      padding-bottom: 32px;
      border-bottom: 2px solid $ant-form-bg;

      &:last-child {
        @include xpay-pre-lg {
          border-bottom: 0;
        }
      }

      li {
        display: flex;
        flex-direction: column;
        padding-right: 24px;

        @include xpay-pre-lg {
          padding: 0 12px;
          justify-content: space-between;
          align-items: flex-start;
        }

        &.mobile-show {
          width: 100%;
          flex-direction: row;

          display: none;
          @include xpay-pre-lg {
            display: flex;
          }

          .col {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            align-items: flex-start;
            padding: 0 12px;
            width: 100%;

            background-image: url("./../../../../../../public/assets/images/thankyou/sphere.svg");
            background-repeat: no-repeat;
            background-position: left;

            @include xpay-pre-lg {
              padding-right: 12px;
              padding-left: 12px;
              padding-bottom: 16px;
            }

            > span.success {
              color: $success;
            }

            .left-side {
              @include xpay-pre-lg {
                width: 100%;
                order: 1;
              }

              img {
                @include xpay-pre-lg {
                  width: 100%;
                }
              }
            }

            .right-side {
              @include xpay-pre-lg {
                order: 2;
              }

              h3 {
                padding-top: 40px;
                font-size: 24px;
                margin-bottom: 24px;
              }

              p {
                max-width: 58ch;
              }
            }

            &.danger {
              background-image: url("./../../../../../../public/assets/images/thankyou/danger-sphere.svg");
              background-repeat: no-repeat;
              background-position: left;
            }

            h3 {
              padding-top: 40px;
              font-size: 32px;
              margin-bottom: 24px;
            }

            p {
              max-width: 58ch;
            }
          }
        }

        &.desktop-show {
          @include xpay-pre-lg {
            display: none;
          }
        }

        > span.success {
          color: $success;
        }

        > span:first-child {
          font-weight: 500;
          opacity: 0.4;
        }

        > span:nth-child(2) {
          font-weight: 500;
        }

        .anticon {
          margin-right: 5px;
        }
      }
    }

    .footer {
      padding-right: 24px;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      @include xpay-pre-lg {
        display: none;
      }

      .xpay-button {
        margin: unset !important;

        button {
          height: 48px !important;
          width: 168px !important;
        }

        button.info {
          background: $primary;
          font-weight: 500;
          font-size: 16px;
        }
      }
    }
  }
}
