@import 'styles/references/index';

.new-password-stage {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;

  > h3 {
    @include xpay-pre-lg {
      display: none;
    }
  }

  .new-password-form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 350px;
    row-gap: 30px;

    @include xpay-pre-md {
      width: 100%;
    }

    .new-password-input {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      width: 100%;

      .input-field {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      input {
        background: transparent;
        height: 40px;
      }

      .start-adornment {
        margin-right: 10px;
      }
      .end-adornment {
        margin-left: 10px;
      }
    }

    .new-password-button {
      max-width: 100%;
      margin: 0;
      button {
        width: 100%;
      }
    }
  }
}
