@import 'styles/references/index.scss';

.xpay-deposit-crypto-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  row-gap: 10px;
  border: 1px solid $table-border;
  border-radius: 10px;
  padding: 15px;
  width: 100%;
  height: 100%;
  margin: 0 auto;

  .anticon.anticon-caret-down {
    color: $info;
  }

  @include xpay-pre-xl {
    height: auto;
  }

  @include xpay-pre-lg {
    width: 100%;
    max-width: unset;
    border: none;
    border-radius: 0;
    padding: 0;
  }

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    row-gap: 10px;
    width: 100%;
    height: 100%;
    max-width: 650px;

    @include xpay-pre-lg {
      display: flex;
      border-radius: 10px;
      border: 1px solid $table-border;
      padding: 15px;
      background: $white;
    }
  }

  .memo-address-input,
  .address-wallet-input {
    input {
      font-family: monospace !important;
      cursor: pointer !important;
    }

    .anticon.anticon-copy {
      margin-left: 8px !important;
    }

    .anticon {
      cursor: pointer !important;
      color: $info;
    }
  }

  .deposit-options {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    row-gap: 24px;
    margin: 32px auto;

    .deposit-value-row,
    .network-options-row,
    .memo-address-input,
    .address-wallet-input,
    .txid-input {
      width: 100%;
      text-align: left;
      margin-bottom: 24px;
    }

    .deposit-value-row,
    .market-options-row,
    .network-options-row {
      width: 100%;
      text-align: left;

      .xpay-alert.warning {
        margin-top: 0px !important;
        margin-bottom: 46px !important;
      }

      .market-selector li,
      .market-selector .xpay-input input {
        cursor: pointer;
      }

      .network-selector li,
      .network-selector .xpay-input input {
        font-family: monospace !important;
        cursor: pointer;
      }

      .market-selector,
      .network-selector {
        position: relative;

        &:hover {
          cursor: pointer !important;
        }

        .networks-dropdown {
          ul li span {
            font-family: monospace !important;
          }
        }

        .markets-dropdown,
        .networks-dropdown {
          position: absolute;
          top: 65px;
          left: 0px;
          max-height: 249px;
          overflow: scroll;
          width: 100%;
          background: $white;
          z-index: 9999;
          border: 1px solid $table-border;
          border-radius: 4px;

          .searchbox {
            width: 100%;
            padding: 8px 16px;

            .xpay-input {
              .input-field {
                display: flex;
                align-items: center;
                width: 100%;
              }

              .anticon {
                color: $info;
                font-size: 18px;
                padding: 0px 16px;
              }
            }
          }

          ul {
            padding: 0px;
            text-align: right;
            border-radius: 4px;
            list-style: none;
            margin: 0px;

            li {
              display: flex;
              align-items: center;
              padding: 0px 16px;
              height: 48px;

              img {
                width: 24px;
                height: 24px;
              }

              span:nth-child(2) {
                margin: 0px 6px;
              }

              span:nth-child(3) {
                margin-left: 4px;
                font-size: 14px;
                opacity: 0.6;
                font-family: monospace;
              }

              &:hover {
                cursor: pointer;
                background: $symbol-btn-bg;
              }
            }

            li:not(:last-child) {
              border-bottom: 1px solid $table-border;
            }
          }
        }
      }
    }

    .payment-recept-uploader {
      margin-top: 32px;
      width: 100%;

      .browse-field {
        width: 100%;
        position: relative;
        border: 1px solid $table-border;
        padding: 5px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;

        label {
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: absolute;
          top: -30px;
          left: 0;
          width: 100%;

          > span:first-child {
            display: flex;
            justify-content: space-between;
            align-items: center;
            column-gap: 3px;

            span {
              font-size: 12px;
            }
          }

          > div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            column-gap: 3px;

            span {
              font-size: 12px;
            }
          }

          > span:last-child {
            display: flex;
            justify-content: space-between;
            align-items: center;
            column-gap: 3px;
            color: $info;
          }
        }

        .browse {
          display: flex;
          justify-content: center;
          width: 100%;
          height: 100%;

          .avatar-uploader {
            width: 100%;
            height: 100%;

            .ant-upload {
              margin: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 100%;
              border: 0.6px dashed $transparent-blue;
              border-spacing: 10px;
              border-radius: 5px;

              button {
                background: transparent;
                border: 0;
                color: $info;
              }
            }
          }
        }
      }
    }

    .xpay-input {
      width: 100%;

      .input-field {
        display: flex;
        align-items: center;
        height: 58px;
        padding: 0px 16px;

        label {
          bottom: 59px;
          right: 3px;
        }

        > .icon {
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: end;

          svg {
            width: 18px;
          }
        }

        .mark {
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;
          column-gap: 5px;
          min-width: fit-content;
          min-height: 28px;
          height: 100%;

          &::after {
            content: '';
            border-left: 2px solid $ant-form-bg;
            padding: 2px;
            height: 26px;
          }

          .selected-item,
          .selected-market {
            display: flex;
            justify-content: space-between;
            align-items: center;
            column-gap: 5px;

            img,
            svg {
              width: 24px;
              height: 24px;
            }

            span {
              width: max-content;
              font-size: 12px;
              font-weight: 500;
            }

            span:last-child {
              font-family: 'Montserrat';
              color: $light-info;
            }
          }
        }
      }
    }

    .input-info-label {
      text-align: left;
      margin-right: auto;
      color: $info !important;
      font-weight: unset;
      font-size: 15px;
    }
  }

  .submit {
    width: 100%;

    .xpay-button {
      position: relative;
      max-width: unset;
      margin: 0;
      height: 48px;

      button {
        background: $primary;
      }

      button {
        @include xpay-pre-xl {
          height: 48px;
        }
      }
    }

    .xpay-button::before {
      content: ' ';
      position: absolute;
      width: 100%;
      height: 100%;
      background-position: -140px;
      background-repeat: no-repeat;
      background-attachment: local;
      background-size: 50%;
    }
  }
}

.confirm-deposit-crypto-modal {
  .confirm-box {
    max-width: 340px;
    top: calc(50% - 246px) !important;
  }

  .content {
    padding: 16px;
    background: #f7f7f7;
    margin: 32px auto;

    ul {
      padding: 0px;
      margin: 0px;
      list-style: none;
      display: flex;
      flex-direction: column;
      row-gap: 16px;

      li {
        display: flex;
        align-items: center;
        justify-content: space-between;

        span:nth-child(2) {
          word-break: break-word;
          text-align: end;
          font-family: monospace !important;
          text-align: left;
        }
      }
    }
  }

  .address-wallet-info {
    display: flex;
    align-items: center;

    justify-content: center;
    width: max-content;
    text-align: center;
    margin: 0px auto;
    margin-top: 16px !important;

    img {
      height: 120px;
    }
  }
}
