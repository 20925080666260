@use 'sass:selector';

$bg: #f1f7ff;
$primary: linear-gradient(95.43deg, #3f62fe -25.7%, #678af5 112.1%);
$secondary: linear-gradient(105.31deg, #21bcf3 -43.21%, #7bd5ed 125.86%);

$black: #000000;
$zero-one-black: #010101;
$blue: #1890ff;
$transparent-blue: #6581fe;

$dark-primary: linear-gradient(83.63deg, #4e5596 -36.87%, #6269aa 117.86%);
$dark-gray: #383852;

$medium-gray: #a8a8a8;
$medium-white: #e9e9e9;
$read-notification: #e9e9ea;
$extra-light-gray: #fbfbfb;

$deposit-shadow: linear-gradient(0deg, #ffffff, #ffffff), #f9fcff;

$disabled-gray-tag: #f6f6f6;
$disabled-tag-text: #9e9e9e;

$light-green: #65c466;
$orange: #e93c3c;
$light-red: #f9f1f0;
$light-blue: #3b6fff;
$cyan: #2ed5ff;
$white-cyan: #c8f1ff;
$sky-blue: #379aff;

$ant-form-bg: #f7f7f7;
$light-success: #41daa3;
$skeleton-bg: #f4f4f4;

$input: #f9f9fd;
$star: #22bdff;
$access: #678af5;

$popover-danger: #ffbdbd;
$popover-warn: #fffbce;
$popover-success: #41daa3;

$success: #2ac769;
$danger: #e93c3c;
$light-info: #5073fb;
$info: #4f46e0;
$primary: #3f62fe;
$warning: #f6a609;
$medium-black: #272727;

$waiting-badge: rgba(246, 166, 9, 0.2);
$success-badge: #d4f4e1;
$success-badge-text: #005f27;
$danger-badge: #fedcdc;
$light-cyan-badge: #3f62fe1a;

$warning-alert: #fef6e6;
$primary-alert: #eef2fe;
$gray-text: #a8a9aa;
$gray-span: #859abc;
$bold-span: #383952;
$table-head-text: #9697a0;
$table-head-bg: #d9dfff33;
$table-head-bg-2: #f1f8ff;

$white: #ffffff;
$c-white: #cccccc;
$nine-white: #999999;
$table-border: #d9dfff;
$d9Gray: #d9d9d9;

$symbol-btn-bg: #f1f8ff;

$alert-message: #383952;

$chart-bg: #efefef;

$night-switch-bg: #5d7294;

$transaction-box-placeholder: #a8a9aa;

$asset-bg: #eff2ff;

$level-badge-bg: #3f62fe99;

$scroll-thumb-bg: #c9cfd8;

$level-up-bg: #4b6efc;

$transaction-card-bg: #fefeff;

$chart-shadow: #ff4e82;

$skeleton-bg-a: #5c6168;
$skeleton-bg-b: #d2dae6;
$skeleton-bg-c: #82868b;
