@import 'src/styles/references/index.scss';

.withdrawl-crypto-networks-modal {
  width: 519px;
  .modal-content {
    padding: 24px 0;
    box-sizing: border-box;
    .head {
      padding: 0 24px;
      box-sizing: border-box;
      margin-bottom: 24px;

      @include xpay-pre-lg {
        padding: 0 16px;
      }

      h4 {
        margin-bottom: 0;

        @include xpay-pre-lg {
          font-size: 22px !important;
        }
      }
    }
    .alert {
      display: flex;
      width: 100%;
      padding: 0 24px;

      @include xpay-pre-lg {
        padding: 0 16px;
      }
      .xpay-alert {
        margin-bottom: 0;

        @include xpay-pre-lg {
          padding: 16px 12px;
        }

        .alert-right-side {
          align-items: center;
        }
        .title {
          @include xpay-pre-lg {
            font-size: 12px;
          }
        }
        .message {
          font-size: 14px;
          font-weight: 500;

          @include xpay-pre-lg {
            font-size: 12px;
          }
        }

        svg {
          @include xpay-pre-lg {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
    .networks-list {
      width: 100%;
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-direction: column;
        width: 100%;
        max-height: 350px;
        overflow-y: auto;
        margin-top: 24px;

        li {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: end;
          cursor: pointer;
          border-radius: 3px;
          padding: 8px 24px;

          @include xpay-pre-lg {
            padding: 8px 16px;
          }

          p {
            font-size: 14px;
            font-weight: 700;
            display: flex;
            justify-content: start;
            align-items: center;
            margin-bottom: 8px !important;

            @include xpay-pre-lg {
              font-size: 12px;
            }
          }

          span {
            font-size: 12px;
            color: $gray-text;

            @include xpay-pre-lg {
              font-size: 10px;
            }
          }

          .ant-tag.unavailable,
          .ant-tag {
            background: $light-red !important;
            color: $danger;
            border: none;
            height: 23px;
            margin-right: 8px;
            font-family: YekanBakh;
            font-weight: 400;
            font-size: 10px;
            border-radius: 5px;

            @include xpay-pre-lg {
              font-size: 10px;
            }
          }

          .not-match {
            background: $disabled-gray-tag !important;
            color: $disabled-tag-text;
          }
          .match {
            background: $success-badge !important;
            color: $success ;
          }
        }

        li.disabled {
          .network-title {
            p {
              color: $disabled-tag-text;
            }
          }
          .network-stats {
            span {
              color: $disabled-tag-text;
            }
          }
        }

        li:first-child {
          margin-top: 0;
        }

        li:hover {
          background: $extra-light-gray;
        }
      }
    }
  }
}

@include xpay-pre-lg {
  .withdrawl-crypto-networks-modal {
    .alert {
      padding: 0 12px;
    }
  }
}
