@mixin rtl {
    @at-root body[dir="rtl"] & {
        @content;
    }
}

@mixin xpay-xs {
    @media (max-width: 575.98px) {
        @content;
    }
}

@mixin xpay-sm {
    @media (min-width: 575.98px) {
        @content;
    }
}

@mixin xpay-md {
    @media (min-width: 767.98px) {
        @content;
    }
}

@mixin xpay-lg {
    @media (min-width: 991.98px) {
        @content;
    }
}

@mixin xpay-xl {
    @media (min-width: 1199.98px) {
        @content;
    }
}
@mixin xpay-pre-xs {
    @media (max-width: 575.98px) {
        @content;
    }
}

@mixin xpay-pre-sm {
    @media (max-width: 575.98px) {
        @content;
    }
}

@mixin xpay-pre-md {
    @media (max-width: 767.98px) {
        @content;
    }
}

@mixin xpay-pre-lg {
    @media (max-width: 991.98px) {
        @content;
    }
}

@mixin xpay-pre-xl {
    @media (max-width: 1199.98px) {
        @content;
    }
}

@mixin xpay-size($property, $web-value, $mobile-value, $important: null) {
    @include xpay-xs {
        #{$property}: $mobile-value $important;
    }
    @include xpay-sm {
        #{$property}: (2 * $mobile-value + $web-value)/3 $important;
    }
    @include xpay-md {
        #{$property}: ($mobile-value + $web-value)/2 $important;
    }
    @include xpay-lg {
        #{$property}: ($mobile-value + 2 * $web-value)/3 $important;
    }
    @include xpay-xl {
        #{$property}: $web-value $important;
    }
}

@mixin rtl {
    @at-root body[dir="rtl"] & {
        @content;
    }
}

@mixin ltr {
    @at-root body[dir="ltr"] & {
        @content;
    }
}
