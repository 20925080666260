@import "src/styles/references/index";

.xpay-price-box-slider {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  min-height: 170px;

  @include xpay-pre-lg {
    display: none;
  }
  .swiper-wrapper {
    .swiper-slide {
      width: 49% !important;
    }
  }

  .head {
    display: none;
    @include xpay-pre-lg {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .right-side {
        display: flex;
        align-items: center;
        span {
          font-size: 18px;
          font-weight: 500;
        }
        .anticon {
          margin-left: 6px;
          margin-bottom: 3px;
          svg path {
            fill: $primary;
          }
        }
      }
      .left-side {
        .anticon {
          background: $white;
          padding: 6px;
          border-radius: 50%;
          box-shadow: 0px -3.675px 20.825px rgba(0, 0, 0, 0.06);
          &.active {
            background: $primary !important;
            svg {
              fill: $white;
            }
          }
        }
        .anticon:first-child {
          margin: 0px 4px;
        }
      }
    }
  }

  .fiat-boxes {
    display: flex;
    width: 100% !important;
    align-items: center;
    column-gap: 24px;
    justify-content: space-between;
  }
}
