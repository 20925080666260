@import 'styles/references/index.scss';

.auth-form {
  margin-top: 24px;
  background: $white;
  padding: 50px 30px 30px;
  border-radius: 20px;

  @include xpay-pre-lg {
    padding: 24px 16px;
  }
}
