@import "styles/references/index.scss";

.xpay-mobile-navigate {
  width: 100%;
  display: none;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  h3 {
    padding: 0 !important;
  }

  cursor: pointer;

  @include xpay-pre-lg {
    display: flex;
  }

  .mob-back-btn {
    opacity: 1;
  }

  //   .mob-back-btn.disabled {
  //     opacity: 0.5;
  //   }
}
