@import 'styles/references/index.scss';

.xpay-dashboard-authentication {
  margin: 0px auto 50px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 25px;
  height: auto;
  margin-bottom: 100px;

  @include xpay-pre-lg {
    flex-direction: column-reverse;
    margin-top: 100px;
    margin-bottom: unset;
    padding: 0 24px;
  }

  @include xpay-pre-md {
    margin-top: 30px;
  }

  .xpay-alert {
    border: none !important;
    padding: 0px !important;
  }
  .mobile-show {
    display: none;
    @include xpay-pre-lg {
      display: unset;
    }
  }

  > .right-side {
    width: 70%;

    @include xpay-pre-lg {
      width: 100%;
      order: 2;
    }

    .slider {
      padding: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 20px;

      @include xpay-pre-lg {
        display: none;
      }

      .swiper {
        width: 100%;
        .swiper-slide {
          display: flex;
          justify-content: center;
          align-items: center;
          .auth-slide {
            border-radius: 20px;
            width: 100%;
            height: 100%;
            min-height: 250px;
            background-image: url('./../../../../public/assets/images/auth/verified.png');
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            padding: 20px 30px;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .content {
              color: $white;
              width: 50%;

              h3 {
                color: $white;
                font-size: 24px;
                font-weight: 500;
              }
            }
          }
        }
      }
    }
  }
  > .left-side {
    width: 30%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    height: 100%;

    @include xpay-pre-lg {
      width: 100%;
      order: 1;
    }

    > .xpay-helper-card {
      .ant-spin-nested-loading {
        width: 100% !important;
      }
      @include xpay-pre-lg {
        display: none;
      }
    }
  }

  .xpay-alert {
    opacity: 0.9;
    border: 1px solid $info;
    background: unset !important;
    margin: unset !important;
    .alert-box .alert-content .message {
      font-size: 12px !important;
    }
  }

  .xpay-helper-box {
    margin-top: 24px;
  }
}
