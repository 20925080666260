@import 'styles/references/index.scss';

.transaction-order-detail-modal, .transaction-order-detail-drawer {

  .modal-content .head {
    margin-bottom: 24px;

    h4 {
      margin-bottom: 0;
    }

  }

  .ant-drawer-body {
    padding: 0 !important;
  }

  .detail-card {
    overflow-y: scroll !important;
    height: 60%;

    .card-body {
      width: 100%;
      margin: 0;
    }

    .head {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      h4 {
        font-size: 22px;
      }

      > svg {
        cursor: pointer;
      }
    }

    .content {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;

      > .row {
        width: 100%;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        display: flex;

        .long-text {
          text-wrap: nowrap;
          max-width: 290px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
        }

        span.en {
          font-weight: 500 !important;
        }

        > span:first-child {
          color: $gray-span;
          font-size: 16px;

        }

        > span:last-child {
          margin-top: 8px;
        }

        > span {
          font-weight: 500;

          span {

            font-weight: 500;
          }
        }

        > div:last-child {
          margin-top: 8px;

          > span.en {
            max-width: 280px;
            overflow-x: hidden;
            text-overflow: ellipsis;
          }

          > span {
            font-size: 16px;
            font-weight: 500;
          }
        }

        .coin-info {
          span {
            font-family: YekanBakh !important;
          }

          img {
            width: 20px;
            height: 20px;
          }

          > span:last-child {
            font-weight: 500;
          }
        }

        .bankaccount {
          img {
            width: 20px;
            height: 20px;
          }

          gap: 1%;
          justify-content: flex-start !important;
          align-items: end;
        }


        svg {
          cursor: pointer;

          path {
          }
        }

      }

      > .row.has-divider::before {
        content: '';
        display: inline-block;
        width: 100%;
        height: 0.5px;
        background: $medium-white;
        margin: 8px;
      }

      > .row.double-column {
        flex-wrap: wrap;
        align-items: start;
        flex-direction: row;

        > div:last-child {
          margin-top: 0;
        }

        .col {
          width: 50%;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          flex-direction: column;

          img {
            margin-left: 8px;
            width: 20px;
            height: 20px;
          }

          > div {
            margin-top: 8px;
            display: flex;
            align-items: center;
            justify-content: start;

            span {
              font-weight: 500;
            }
          }

          > span:first-child {
            color: $gray-span;
            font-size: 16px;
            font-weight: 500;
          }
        }
      }

      span.success {
        color: $success;
      }

      > .row > span.error {
        color: $danger;
      }

      span.waiting {
        color: $warning;
      }

      > .row {
        flex-direction: column;

        > span:first-child {
          width: 100%;
        }

        > div:last-child,
        > span:last-child {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
  }
}
