@import "src/styles/references/index.scss";

.xpay-wallet-asset-cart {
  margin-bottom: 24px;
  background: $white;
  width: 100%;
  padding: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  border: 1px solid $table-border;
  border-radius: 20px;
  margin: 24px auto;

  &.mobile {
    max-width: 425px;
  }

  &:hover {
    background: $asset-bg;
  }

  @include xpay-pre-lg {
    margin-bottom: 24px;
    background: $white;
    width: 100%;
    padding: 16px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    border: 1px solid $table-border;
    border-radius: 20px;
    max-width: 425px;
  }

  img {
    height: 28px !important;
  }

  &.pinned-coin {
    background: $asset-bg;
    border-color: $info;
  }

  .desktop-show {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    column-gap: 48px;
    height: 42px;

    &.pinned-coin {
      background: $asset-bg;
      border-color: $info;
    }

    .stats {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;

      span {
        min-width: 150px;
        margin-left: 28px;

        @include xpay-pre-xl {
          min-width: unset;
        }

        &.irt-balance {
          @include xpay-pre-xl {
            display: none;
          }
        }
      }
    }

    .asset-info {
      display: flex;
      align-items: center;
      column-gap: 8px;
      width: max-content;
      min-width: 140px;

      .coin-name {
        display: flex;
        flex-direction: column;

        span.en {
          opacity: 0.6;
        }
      }
    }

    span.actions {
      display: flex;
      justify-content: flex-end;
      gap: 10px;
      /* a.deposit {
         margin-left: 16px;
       }*/
      .xpay-button {
        button {
          padding: 4px 24px !important;
          height: 32px;
          width: max-content;
        }

        &:hover {
          &.deposit-button button {
            border: 1px solid $success;
          }

          &.widthrawl-button button {
            border: 1px solid $danger;
          }
        }
      }

      .xpay-button.deposit-button button {
        background: $success-badge;
        color: $success;
      }

      .xpay-button.widthrawl-button button {
        color: $danger;
        background: $danger-badge;
      }

      .xpay-button:nth-child(2) {
        margin-right: 16px;
      }
    }

    b {
      font-weight: 500;
    }
  }

  .mobile-show {
    width: 100%;

    .asset-info {
      display: flex;
      align-items: center;
      column-gap: 8px;

      width: 100%;
      justify-content: space-between;

      .coin-name {
        display: flex;
        flex-direction: column;

        span.en {
          opacity: 0.6;
        }
      }
    }

    .stats {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      margin: 16px 0px;
      width: 100%;

      li {
        justify-content: space-between;
        width: 100%;
        display: flex;
      }
    }

    .actions {
      display: flex;
      justify-content: space-between;
      gap: 10px;
      width: 100%;

      a {
        max-width: unset;
        margin: 0;
        width: 45%;

        button {
          height: 32px;
        }

        &:first-child {
          button {
            border: 1px solid $success;
            color: $success;
            background: $success-badge;
          }
        }

        &:nth-child(2) {
          button {
            border: 1px solid $danger;
            color: $danger;
            background: $danger-badge;
          }
        }

        &:nth-child(3) {
          button {
            border: 1px solid $info;
            color: blue;
            background: $primary-alert;
          }
        }
      }
    }

    &.pinned-coin {
      background: $asset-bg;
      border-color: $info;
    }

    > div {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;

      .right,
      .left {
        display: flex;
        width: max-content;
      }
    }

    > div:first-child {
      .right {
        justify-content: flex-start;
        align-items: center;

        img,
        p,
        span {
          margin-left: 8px;
        }

        img {
          width: 25px;
          height: 25px;
        }

        p {
          font-size: 12px;
          font-weight: 500;
        }

        span {
          color: $gray-text;
          font-family: "Montserrat";
          font-size: 12px;
        }
      }

      .left {
        justify-content: flex-start;
        align-items: center;

        p {
          font-family: "Montserrat" !important;
          font-size: 12px;
        }
      }
    }

    > div:nth-child(2),
    > div:nth-child(3) {
      .left {
        font-weight: 500;
      }
    }

    .actions {
      display: flex;
      justify-content: space-between;
      gap: 10px;

      a {
        max-width: unset;
        margin: 0;
        width: 45%;

        button {
          height: 32px;
        }

        &:first-child {
          button {
            border: 1px solid $success;
            color: $success;
            background: $success-badge;
          }
        }

        &:nth-child(2) {
          button {
            border: 1px solid $danger;
            color: $danger;
            background: $danger-badge;
          }
        }

        &:nth-child(3) {
          button {
            border: 1px solid $info;
            color: blue;
            background: $primary-alert;
          }
        }
      }
    }
  }
}
