@import 'styles/references/index.scss';

.complete-stage {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: c;
  row-gap: 40px;
  width: 100%;

  @include xpay-pre-lg {
    row-gap: 24px;
  }

  .anticon {
    svg {
      width: 75px;
      height: 75px;
      fill: $success;
    }
  }

  p {
  }
}
