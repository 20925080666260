@import 'src/styles/references/index';

.level-card-list {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  &.ant-spin-spinning {
    align-items: center;
    justify-content: center;
    max-width: 425px;
    min-height: 400px;
    height: 100%;
  }
}
