@import 'styles/references/index.scss';

.xpay-notifications-page {
  border-radius: 20px;

  @include xpay-pre-lg {
    width: calc(100% - 48px);
    margin: 30px 24px 0;
  }

  .xpay-panel-card {
    .card-body {
      margin: 0 auto;
      display: flex;
      height: max-content;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      margin-bottom: 10%;

      @include xpay-pre-lg {
        padding-bottom: 0;
      }

      h4 {
        font-size: 24px;
        font-weight: 500;
      }

      .xpay-button {
        button.info {
          background: $primary;
        }

        button.outlined {
          transition: 0.4s;
        }

        button.outlined:hover {
          background: $primary;
          transition: 0.4s;
        }
      }

      .head {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include xpay-pre-lg {
          flex-direction: column;
        }

        h4 {
          @include xpay-pre-lg {
            width: 100%;
            text-align: right;
            margin-bottom: 16px;
          }
        }

        .tags {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          @include xpay-pre-lg {
            width: 100%;
          }

          .xpay-button {
            margin-left: 5px;
            width: max-content;
            max-width: unset;

            button {
              font-size: 12px;
              height: 38px;
            }
          }
        }
      }

      .main {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;

        .message {
          position: relative;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: column;
          cursor: pointer;

          @include xpay-pre-lg {
            align-items: flex-start;
          }

          .row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            &:last-child {
              @include xpay-pre-lg {
                align-items: flex-start;
                flex-direction: column;
              }
            }

            .left {
              display: flex;
              justify-content: flex-end;
              align-items: center;
              text-align: left;

              a {
                margin-top: 15px;
              }
            }

            .right {
              display: flex;
              justify-content: space-between;
              align-items: flex-start;

              .circle {
                position: absolute;
                right: -18px;
                top: 6px;
                display: block;
                width: 8px;
                height: 8px;
                background: linear-gradient(
                  95.43deg,
                  $primary -25.7%,
                  $access 112.1%
                );
                border-radius: 50%;
              }
            }

            span.title {
              font-weight: 500;
            }

            p {
              margin-top: 15px;
            }
          }
        }
      }

      .footer {
        width: 100%;
        min-height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;

        > .anticon {
          cursor: pointer;
          margin-right: 10px;
          padding: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 6px;
          border: 1px solid $table-border;
          height: 40px;
          width: 40px;
          color: $info;
          transition: 0.4s;

          &:hover {
            background: linear-gradient(
              95.43deg,
              $primary -25.7%,
              $access 112.1%
            );
            color: $white;
            transition: 0.4s;
          }
        }

        .pages {
          display: flex;
          justify-content: center;
          align-items: center;

          .xpay-button {
            margin-right: 10px;

            button {
              height: 40px;
              width: 40px;
              padding: 10px;
              border-radius: 6px;
              border: 1px solid $table-border !important;
            }
          }
        }
      }
    }
  }
}
