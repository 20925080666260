@import 'styles/references/index.scss';

.xpay-credit-card-slider {
  background: $white;
  border-radius: 20px;
  width: 100%;
  text-align: center;
  .pre-navigation {
    background: rgba(80, 115, 251, 0.2);
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 10px;
    left: 0;
    top: calc(52% - 20px);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
  }

  .next-navigation {
    background: rgba(80, 115, 251, 0.2);
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 10px;
    cursor: pointer;
    right: 0;
    top: calc(52% - 20px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    transform: rotate(180deg);
  }
}
