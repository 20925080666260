@import 'styles/references/index.scss';

.xpay-coins-dropdown {
  position: absolute;
  top: 105%;
  right: 0;
  margin: 0;
  width: 100%;
  list-style-type: none;
  display: none;
  justify-content: center;
  align-items: flex-start;
  background: $white;
  flex-direction: column;
  border: 1px solid $symbol-btn-bg;
  border-radius: 8px;
  row-gap: 10px;
  z-index: 1001;
  &.active {
    display: flex;
  }
  .searchbox {
    width: 100%;
    padding: 8px 16px;
    .xpay-input {
      .input-field {
        display: flex;
        align-items: center;
        width: 100%;
      }
      .anticon {
        color: $info;
        font-size: 18px;
        padding: 0px 16px;
      }
    }
  }
  > span {
    display: block;
    margin: 0 auto;
    width: 100%;
    height: 1px;
    background: $table-border;
  }

  ul {
    padding: 0px;
    width: 100%;
    max-height: 190px;
    overflow-y: scroll;
    li {
      height: 35px;
      padding: 24px 0px;
      border-bottom: 1px solid $ant-form-bg;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-width: 100px;
      column-gap: 10px;

      &:hover {
        background: $symbol-btn-bg;
      }

      .coin-info {
        display: flex;
        justify-content: start;
        align-items: center;
      }

      &:not(.pinned) > .coin-info {
        display: flex;
        align-items: center;
        padding-right: 16px;
        span:nth-child(2) {
          margin-right: 6px;
        }
        span:nth-child(3) {
          font-family: monospace;
          font-size: 12px;
          margin-right: 4px;
          opacity: 0.6;
        }
      }

      &.pinned .coin-info {
        .anticon:first-child {
          padding-right: 16px;
          margin-top: 4px;
          svg {
            width: 16px;
            height: 16px;
          }
        }
        img:nth-child(2) {
          margin-right: 6px;
        }
        span:nth-child(3) {
          margin-right: 6px;
        }
        span:nth-child(4) {
          font-family: monospace;
          font-size: 12px;
          margin-right: 4px;
          opacity: 0.6;
        }
      }

      .price-info {
        padding-left: 16px;
        span {
          font-size: 12px;
        }
        span b {
          font-weight: 300;
        }
      }
      img {
        width: 18px;
        height: 18px;
      }
    }
  }
}
