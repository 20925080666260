@import 'styles/references/index.scss';

.xpay-create-bank-account {
  width: 100%;
  height: auto;
  background: $white;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 24px;
  @include xpay-pre-md {
    padding: 0px;
    flex-direction: column;
    margin-bottom: 0;
  }

  .xpay-panel-card {
    padding: 0px !important;
  }
  .left-side::before {
    position: absolute;
    right: 0%;
    top: 10%;
    content: '';
    display: block;
    width: 1px;
    height: 75%;
    background: $table-border;

    @include xpay-pre-md {
      display: none !important;
    }
  }

  .right-side {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 55%;
    height: 100%;

    @include xpay-pre-md {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
    }

    > .card-body {
      @include xpay-pre-lg {
        width: 100%;
      }
    }

    form {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      row-gap: 30px;
      width: 95%;
      padding: 15px;

      @include xpay-pre-lg {
        width: 100%;
        padding: 0 16px;
        row-gap: 0;
      }

      .main {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        row-gap: 25px;

        .xpay-input:first-child {
          margin-top: 24px;
          input {
            &::placeholder {
              color: inherit;
            }
            font-size: 18px;
            .rtl {
              text-align: right !important;
            }
            .ltr {
              text-align: left !important;
            }
            font-weight: 300;
            @include xpay-pre-md {
              font-size: 14px;
            }
          }
        }
        .xpay-input:nth-child(2) {
          input {
            font-size: 18px;
            font-weight: 300;
            @include xpay-pre-md {
              font-size: 14px;
            }
          }
        }
        .xpay-input {
          width: 100%;
          margin-bottom: 22px;
          .input-field {
            padding: 0 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            label {
              bottom: 58px !important;
              font-weight: 800;
            }
            input {
              background: transparent;
              height: 57px !important;
            }
            span {
              font-size: 22px;
            }
            span:nth-child(2) {
              margin: 0 5px;
            }
            span:last-child {
              margin: 0 0px 0 5px;
              font-family: 'Montserrat';
              color: $gray-span;
            }

            img {
              width: 25px;
              height: 25px;
            }
          }
        }
      }

      .xpay-button {
        max-width: unset;
        opacity: 1;

        &.disabled {
          opacity: 0.5;
        }
      }
    }
  }

  .left-side {
    position: relative;
    width: 95%;
    padding: 15px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    row-gap: 20px;
    width: 45%;
    height: 100%;
    @include xpay-pre-md {
      width: 100%;
      padding-top: 24px;
      padding-bottom: 24px;
    }

    p {
      text-align: right;
      font-size: 14px;
      @include xpay-pre-md {
        display: none;
      }
    }
  }

  .xpay-alert {
    width: 100% !important;
  }
}
