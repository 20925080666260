@import 'styles/references/index.scss';
.xpay-pagination {
  width: 100%;
  margin-top: 32px;
  text-align: center;

  .pagination-row {
    display: flex;
    justify-content: center;
    align-items: center;

    > .anticon {
      cursor: pointer;
      margin-right: 10px;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      border: 1px solid $table-border;
      height: 40px;
      width: 40px;
      color: $info;
      transition: 0.4s;

      @include xpay-pre-lg {
        margin-right: 3px;
      }

      &.anticon-double-right,
      &.anticon-double-left {
        @include xpay-pre-lg {
          display: none;
        }
      }

      &.disabled {
        opacity: 0.5;
      }

      &:hover {
        background: $primary;
        color: $white;
        transition: 0.4s;
      }
    }

    .pages {
      display: flex;
      justify-content: center;
      align-items: center;

      .xpay-button {
        margin-right: 10px;
        @include xpay-pre-lg {
          margin-right: 3px;
        }
        button {
          height: 40px;
          width: 40px;
          padding: 10px;
          border-radius: 6px;
          border: 1px solid $table-border !important;

          &.info {
            background: $primary;
          }
        }
      }
    }
  }

  .foot {
    margin: 16px auto;
    font-size: 16px;
    b {
      font-weight: 500;
      margin: 0px 4px;
    }
  }
}
