@import 'src/styles/references/index.scss';

.order-confirm-modal .detail-card {
  overflow-y: auto !important;
  left: calc(50% - 220px);
  height: max-content;

  @include xpay-pre-lg {
    top: 20px;
  }

  @include xpay-pre-lg {
    top: 20px;
    left: calc(50% - 165px);
  }

  .card-body {
    width: 100%;
    margin: 0;
  }

  .head {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h4 {
      font-size: 22px;
    }

    > svg {
      cursor: pointer;
    }
  }

  .content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }
}

.xpay-create-order {
  width: 100%;
  height: max-content;
  column-gap: 5px;
  background: $white;
  border-radius: 20px;
  margin-bottom: 8px;

  @include xpay-pre-lg {
    width: 100%;
    margin: 60px auto;
    background: $bg;
  }
  @include xpay-pre-md {
    width: 100%;
    padding: 0 24px;
    margin: 0 auto;
    margin-top: 30px;
  }

  .xpay-alert {
    .message,
    a {
      font-weight: 500 !important;
      font-size: 14px !important;
      margin-bottom: 5px !important;
    }
  }

  .ant-row > .xpay-alert {
    display: none;

    @include xpay-pre-lg {
      display: block;
    }
    .message {
      @include xpay-pre-lg {
        font-size: 14px !important;
        width: 100%;
        text-align: center;
      }
    }
  }

  .mobile-show {
    display: none;
    @include xpay-pre-lg {
      display: flex;
    }
  }

  .desktop-show {
    @include xpay-pre-lg {
      display: none;
    }
  }

  .right-side {
    padding: 32px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include xpay-pre-lg {
      padding: 0px;
    }
    .xpay-transaction-box {
      margin: 0 auto;
      border: 0px !important;

      input {
        @include xpay-pre-lg {
          font-size: 16px;
        }
      }
      &.sell {
        .calculator .field:first-child .symbol {
          border-radius: 10px;
        }
      }
      &.buy {
        .calculator .field:nth-child(2) .symbol {
          border-radius: 10px;
        }
      }
      .xpay-alert {
        margin-top: 32px !important;

        @include xpay-pre-lg {
          margin-top: 16px !important;
        }
      }
      .calculator {
        margin-top: 24px;
        .field {
          .symbol {
            width: 45%;
          }
        }
      }
    }
  }
  > .left-side {
    background: $deposit-shadow;
    box-shadow: 0px 0px 20px 0px rgb(19 42 43 / 8%);

    @include xpay-pre-lg {
      box-shadow: unset;
      margin-top: 24px;
      border-radius: 10px;

      .xpay-helper-card {
        display: none;

        border-radius: 10px !important;
      }
    }
  }
}
.help-bar-row {
  @include xpay-pre-lg {
    display: none;
    margin: 16px auto;
    width: 90%;
  }
}

.xpay-modal.order-notif-modal {
  .modal-content {
    border-radius: 16px;

    > .head {
      display: none;
    }

    .confirm-box {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      min-height: 100px;
      width: 328px;
      height: 202px;

      @include xpay-pre-lg {
        width: 100%;
      }

      .buttons {
        .xpay-button {
          margin-right: 0;
          button {
            height: 42px;
            background: linear-gradient(
              95.43deg,
              $primary -25.7%,
              $access 112.1%
            );
          }
        }
      }

      > .head {
        justify-content: center;
      }
    }
  }
}
