@import 'src/styles/references/index.scss';

.xpay-dashboard-main {
  padding: 0 15px;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  column-gap: 10px;
  background: $white;
  border-radius: 20px;
  margin-bottom: 100px;

  @include xpay-pre-lg {
    flex-direction: column;
    width: 60vw;
    background: $bg;
    margin: 80px auto;
    height: auto;
    padding: 0;
  }

  @include xpay-pre-md {
    width: 100%;
    margin: 0 auto;
  }
  .ant-spin-nested-loading {
    width: 100%;
    .ant-spin-container {
      width: 100%;
    }
  }
  > .left-side {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 30%;

    @include xpay-pre-lg {
      display: none;

      .xpay-recent-transactions {
        .header {
          display: flex;
        }
      }
    }
  }

  > .right-side {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex-direction: column;
    row-gap: 24px;

    background: linear-gradient(
      321.04deg,
      $white 0.08%,
      rgba(241, 247, 255, 0.5) 125.26%
    );
    width: 70%;

    .xpay-balance,
    .xpay-recent-transactions {
      display: none;
    }

    @include xpay-pre-lg {
      width: 100%;
      row-gap: 24px;
      background: transparent;

      .xpay-balance,
      .ant-spin-nested-loading,
      .xpay-recent-transactions {
        display: flex;
      }

      .xpay-balance {
        order: 1;
      }

      .footer {
        order: 2;
      }

      > .header {
        order: 3;
      }

      .main {
        order: 4;
      }

      .xpay-recent-transactions {
        order: 7;
      }
    }

    > .header {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding-top: 25px;
      margin: 0 auto;

      @include xpay-pre-lg {
        align-items: center;
        height: auto;
        padding: 0;
      }

      .xpay-slider {
        .swiper {
          @include xpay-pre-lg {
            padding: 0 24px 32px;
          }
        }
        @include xpay-pre-lg {
          .pre-navigation {
            border-radius: 50%;
            left: 5px;
            top: calc(50% - 30px);
            width: 34px;
            height: 34px;
            svg {
              width: 15px;
              height: 15px;
            }
          }

          .next-navigation {
            border-radius: 50%;
            right: 5px;
            top: calc(50% - 30px);
            width: 34px;
            height: 34px;
            svg {
              width: 15px;
              height: 15px;
            }
          }
        }
      }
    }

    .links-row {
      width: 100%;
    }
    > .main {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      column-gap: 10px;
      flex-wrap: nowrap;
      margin: 0px auto;
      padding: 0 32px;

      @include xpay-pre-lg {
        flex-direction: column;
        padding: 0 24px;
        align-items: center;
        row-gap: 16px;
      }

      .xpay-transaction-box {
        .payment-type-options {
          margin: 8px 0;
          display: flex;
          width: 100%;
          justify-content: space-between;

          @include xpay-pre-lg {
          }

          > label {
            color: $info;
            font-weight: 500;
            @include xpay-pre-lg {
              display: none;
            }
          }
        }
      }

      .xpay-telegram-bot {
        position: relative;
        display: flex !important;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        row-gap: 10px;
        border-radius: 10px;
        background: $primary;
        width: 100%;
        height: 168px;
        border-radius: 10px;
        padding: 20px 30px;

        @include xpay-pre-lg {
          width: 100%;
          max-width: unset;
          display: none;
        }

        &.mobile-show {
          display: none !important;
          flex-direction: row;
          height: auto;
          padding: 16px;

          @include xpay-pre-lg {
            display: flex !important;
          }

          .text > div > img,
          .text > div > p {
            display: none;
          }

          .text > p {
            font-size: 16px;
            padding-left: 16px;
            font-weight: 500;
          }

          button {
            svg {
              margin-left: 8px;
            }
            span {
              font-size: 16px;
            }
          }
        }

        &.desktop-show {
          display: flex;
          @include xpay-pre-lg {
            display: none !important;
          }
        }

        .overlay {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          opacity: 0.5;
          background-image: url('./../../../../public/assets/images/slides/mask.svg');
          background-position: center;
          background-size: cover;
          z-index: 1;
        }

        .text {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          flex-direction: column;
          row-gap: 5px;
          position: relative;
          z-index: 5;

          @include xpay-pre-lg {
            width: 50%;
          }

          > div {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            p {
              color: $white;
              font-size: 20px;
              font-weight: 500;
            }
          }
          > p {
            color: $white;
            font-size: 10px;
          }
        }
        button {
          position: relative;
          width: 100%;
          margin: 0 auto;
          cursor: pointer;
          max-width: 180px;
          border: 0;
          outline: 0;
          width: 100%;
          height: 48px;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          column-gap: 4px;
          border-radius: 8px;
          background: $white;
          border: 1px solid $info !important;
          color: $info;
          transition: 0.4s;
          padding: 8px 16px;
          z-index: 3;

          @include xpay-pre-lg {
            max-width: 160px;
            height: 45px;
          }

          span {
            font-weight: 500;
          }

          &:hover {
            cursor: pointer;
            background: $primary;
            color: $white;
            transition: 0.4s;

            svg {
              path {
                fill: $white !important;
              }
            }
          }
        }
      }

      > .links-row.mobile-show {
        padding: 12px 14px;
        border: 1px solid $table-border;
        margin: 0;
        display: none;
        border-radius: 10px;

        @include xpay-pre-lg {
          display: flex;
        }

        .card-body {
          width: 100%;
          margin: 0;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .link-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: column;

            > img {
              width: 28px;
              height: 28px;
              margin-bottom: 12px;
            }
            > p {
              color: $gray-span;
              font-size: 12px;
            }
          }
        }
      }

      .banner-columns {
        width: 50%;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        flex-direction: column;
        flex-wrap: wrap;
        min-width: 210px;
        row-gap: 10px;
        flex: 35%;

        @include xpay-pre-lg {
          width: 100%;
          flex-direction: column;
          align-items: center;
          row-gap: 30px;
        }
        .xpay-upgrade-level {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          row-gap: 10px;
          overflow: hidden;
          border-radius: 10px;
          background: $white;
          width: 100%;
          min-height: 168px;
          border: 1px solid $table-border;
          border-radius: 10px;

          @include xpay-pre-lg {
            width: 100%;
            max-width: unset;
            display: none;
          }

          .overlay {
            position: absolute;
            width: 397px;
            height: 287px;
            left: -244px;
            top: -217px;

            background: $primary;
            opacity: 0.6;
            filter: blur(200px);
          }

          .level {
            width: 100%;
            padding: 5px 30px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            > div {
              width: 100%;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              column-gap: 5px;

              p {
                color: $info;
                font-weight: 500;
                font-size: 14px;
                line-height: 28px;

                @include xpay-pre-lg {
                  font-size: 18px;
                }
              }

              .pros {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                column-gap: 5px;
                cursor: pointer;

                > span:first-child {
                  color: $gray-span;
                  font-weight: 500;
                  font-size: 12px;
                  line-height: 15px;

                  @include xpay-pre-lg {
                    font-size: 10px;
                  }
                }
              }
            }

            > svg {
              display: none;

              @include xpay-pre-lg {
                display: block;
                position: relative;
              }
            }
          }

          .xpay-button {
            width: 85%;
            max-width: unset;
            button {
              background: $primary;
            }
          }
        }

        img:first-child {
          margin-bottom: 8px;
        }
      }
    }
    > .footer {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      width: 100%;
      padding: 0 32px;
      margin: 0 auto;

      .head {
        width: 100%;
        display: none;
        @include xpay-pre-lg {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .right-side {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            span {
              font-size: 18px;
              font-weight: 500;
            }
            .anticon {
              margin-left: 6px;
              margin-bottom: 3px;
              svg path {
                fill: $primary;
              }
            }
          }
          .left-side {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding: 10px 0;
            .xpay-button {
              justify-self: flex-end;
              width: auto;
              button {
                background: transparent;
                display: none;
                justify-content: center;
                align-items: center;
                width: 120px;
                height: 30px;
                column-gap: 4px;
                font-size: 12px;
                color: $info;
                border: 1px solid $table-border;
                @include xpay-pre-lg {
                  display: flex;
                }
              }

              @include xpay-pre-lg {
                display: flex;
              }
            }
          }
        }
      }

      @include xpay-pre-lg {
        width: 100%;
        padding: 0px;
        align-items: center;
      }

      .xpay-mobile-show {
        width: 100%;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        row-gap: 15px;

        @include xpay-pre-lg {
          display: flex !important;
        }

        .xpay-price-box {
          @include xpay-pre-lg {
            margin: 0;
            width: 100%;
          }
        }
      }
    }
  }
}
