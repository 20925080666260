@import 'styles/references/index';

.signup-stage {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 350px;
  row-gap: 30px;

  @include xpay-pre-lg {
    flex-direction: column;
    padding: 0px 0px 0px;
    height: 100%;
  }

  > h3 {
    @include xpay-pre-lg {
      display: none;
    }
  }

  form {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 30px;

    .rules {
      font-size: 16px;
      margin-left: auto;
    }

    .signup-input {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      width: 100%;

      .input-field {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      input {
        background: transparent;
        height: 40px;
      }

      .start-adornment {
        margin-right: 10px;
      }

      .end-adornment {
        margin-left: 10px;
      }
    }

    .signup-options {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;

      .signup-policy {
        margin-top: 20px;

        > span {
          margin-right: 5px;
          @include xpay-size(font-size, 20px, 10px);
        }
      }
    }

    .signup-button {
      width: 100%;
      margin: 0;
      max-width: none;
    }

    .signup-referral {
      font-size: 15px;
      cursor: pointer;
      padding-bottom: 30px;

      @include xpay-pre-md {
        padding-bottom: 0;
      }

      > span {
        color: $blue;
      }

      > span.active {
        transform: rotate(-90deg);
      }

      a {
        margin-right: 5px;
      }
    }

    .signup-referral-box {
      width: 100%;
      margin-top: -20px;
      margin-bottom: 20px;
      height: 40px;

      @include xpay-pre-md {
        margin-top: 0;
        margin-bottom: 10px;
      }

      input {
        height: 40px;
        padding: 0 10px;
        background: transparent;
      }
    }
    .other-options {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding-right: 4px;

      .signin {
        span {
          font-size: 15px !important;
        }
        a {
          font-size: 15px;
          text-decoration: none;
          font-weight: 500;
          margin-right: 5px;
          color: $info;
        }
      }
    }

    .signup-policy {
      span {
        font-size: 15px !important;
      }

      a {
        font-size: 15px;
      }
    }
  }
}
