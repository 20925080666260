@import 'src/styles/references/index';

.xpay-toast {
  min-width: 250px;
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 16px;
  border-radius: 18px;
  z-index: 2000000002 !important;

  .alert-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .alert-right-side {
      display: flex;
      align-items: center;
      flex-direction: row;
      width: 100%;

      .title {
        font-weight: 500;
      }

      .alert-icon {
        display: flex;

        svg {
          height: 36px;
          margin-top: 2px;
        }
      }
    }

    .alert-left-side {
      display: flex;
      color: $white;
      margin-right: 8px;
    }
  }

  &.error {
    background: $danger-badge;
    border-color: $danger;

    .title,
    .anticon-close {
      color: $danger;
    }
  }

  &.success {
    background: $success-badge;
    border-color: $success;

    .title,
    .anticon-close {
      color: $success;
    }
  }

  &.warn {
    background: $warning-alert;
    border-color: $warning;

    .title,
    .anticon-close {
      color: $warning;
    }
  }

  &.info {
    border-color: $primary-alert;

    background: $primary;

    .title,
    .anticon-close {
      color: white;
    }
  }

  .alert-box {
    display: flex;

    .alert-content {
      max-width: 90%;

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      .title {
      }

      .message {
        color: $alert-message;
        font-weight: 400;
        text-align: right;
        font-size: 12px;
        @include xpay-pre-lg {
          font-size: 10px !important;
        }
      }
    }
  }
}
