@import 'styles/references/index.scss';

.currency-widthrawl-confirm-modal {
  .modal-content > .head {
    display: none;
  }
}

.xpay-widthrawl-currency-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  row-gap: 10px;
  border: 1px solid $table-border;
  border-radius: 10px;
  padding: 15px;
  width: 100%;
  height: 100%;

  .anticon.anticon-caret-down {
    color: $info;
  }

  @include xpay-pre-xl {
    height: auto;
  }

  @include xpay-pre-lg {
    width: 100%;
    max-width: unset;
    border: none;
    border-radius: 0;
    padding: 0;
  }

  .switch-item {
    width: 100%;
  }

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    row-gap: 10px;
    width: 100%;
    height: 100%;
    @include xpay-pre-lg {
      display: flex;
      border-radius: 10px;
      border: 1px solid $table-border;
      padding: 15px;
      background: $white;
    }
  }

  .xpay-switch {
    margin-bottom: 36px;
  }

  .widthrawl-options {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    row-gap: 24px;
    margin: 32px auto;

    .widthrawl-value-row {
      width: 100%;
      text-align: left;

      .options {
        min-width: 28ch;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include xpay-pre-lg {
          display: none;
        }

        .select-available-balance {
          border-radius: 10px;
          color: $info;
          margin-left: 6px;
          padding: 6px;
          background: rgba(80, 115, 251, 0.05);
          cursor: pointer;
        }
      }
    }

    .payment-options-row {
      width: 100%;
      text-align: left;

      .xpay-alert.warning {
        margin-top: 0px !important;
        margin-bottom: 46px !important;
      }

      .add-new-cart {
        border: 1px solid $table-border;
        border-radius: 10px;
        width: 100%;
        height: 52px;
        padding: 0px 16px;
        cursor: pointer;
        display: flex;
        align-items: center;

        span.anticon {
          margin-bottom: 3px;
        }

        span:nth-child(2) {
          margin-right: 8px;
        }
      }

      .payment-cart-selector {
        position: relative;

        input {
          font-size: 18px;
          text-align: start;
          direction: ltr;
        }

        &:hover {
          cursor: pointer !important;
        }

        .carts-dropdown {
          position: absolute;
          top: 65px;
          left: 0px;
          width: 100%;
          background: $white;
          z-index: 998;
          border: 1px solid $table-border;
          border-radius: 4px;

          ul {
            padding: 0px;
            text-align: right;
            border-radius: 4px;
            list-style: none;
            margin: 0px;

            li {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 0px 16px;
              height: 48px;

              .bank-info {
                span {
                  margin-right: 8px;
                }

                img {
                  height: 24px;
                }
              }

              span {
                direction: ltr;
              }

              &:hover {
                cursor: pointer;
                background: $symbol-btn-bg;
              }
            }

            li:not(:last-child) {
              border-bottom: 1px solid $table-border;
            }
          }
        }
      }
    }

    .xpay-input {
      width: 100%;

      .input-field {
        display: flex;
        align-items: center;
        height: 58px;
        padding: 0px 16px;

        label {
          bottom: 62px;
        }

        .mark {
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;
          column-gap: 5px;
          min-width: fit-content;
          min-height: 28px;

          &::after {
            content: '';
            border-left: 2px solid $ant-form-bg;
            padding: 2px;
            height: 26px;
          }

          .selected-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            column-gap: 5px;

            img,
            svg {
              width: 28px;
              height: 28px;
            }

            span {
              width: max-content;
              @include xpay-size(font-size, 16px, 12px);
              font-weight: 500;
            }

            span:last-child {
              font-family: monospace;
            }
          }

          .selected-cart {
            display: flex;
            justify-content: space-between;
            align-items: center;
            column-gap: 5px;

            input {
              text-align: end;
              font-size: 18px;
            }

            img,
            svg {
              width: 28px;
              height: 28px;
            }

            span {
              width: max-content;
              font-size: 16px;
              font-weight: 500;
            }
          }
        }
      }
    }

    .input-info-label {
      text-align: left;
      margin-right: auto;
      color: $info !important;
      font-weight: unset;
      font-size: 11px;
    }
  }

  .submit {
    width: 100%;

    .xpay-button {
      position: relative;
      max-width: unset;
      margin: 0;
      height: 48px;

      button {
        background: $primary;
      }

      button {
        @include xpay-pre-xl {
          height: 48px;
        }
      }
    }

    .xpay-button::before {
      content: ' ';
      position: absolute;
      width: 100%;
      height: 100%;
      background-position: -140px;
      background-repeat: no-repeat;
      background-attachment: local;
      background-size: 50%;
    }
  }
}
