@import 'styles/references/index.scss';

.xpay-dashboard-referral .referral-history .mobile-show li {
  > .row {
    .right {
      justify-content: flex-start;
      align-items: center;

      p,
      span {
        margin-left: 8px;
        @include xpay-pre-lg {
          font-size: 12px;
        }
      }

      p {
      }

      span {
        font-weight: 500;
      }
    }

    .left {
      justify-content: flex-end;
      align-items: center;

      p,
      span {
        margin-right: 3px;
        @include xpay-pre-lg {
          font-size: 12px;
        }
      }

      p {
      }

      span {
        font-weight: 500;
      }
    }
  }
  > .row:first-child {
    .right {
      justify-content: flex-start;
      align-items: center;

      p,
      span {
        margin-left: 8px;
      }

      p {
      }

      span {
        font-weight: 500;
      }
    }

    .left {
      justify-content: flex-start;
      align-items: center;

      p,
      span {
        margin-right: 3px;
      }

      p {
      }

      span {
        font-weight: 500;
      }
    }
  }

  > .row:nth-child(2) {
    .left,
    .right {
      display: flex;
      align-items: center;
      p {
        margin-right: 3px;
      }
      span {
        margin-right: 3px;
      }
    }
    .right {
      span {
        margin-right: 0;
        font-size: 10px;
        font-weight: 400;
      }
    }
  }

  > .row:nth-child(3) {
    .right {
      span {
        margin-right: 3px;

        color: $gray-text;
      }
    }
  }
}
