@import "src/styles/references/index";

.xpay-badge {
  max-width: 180px;
  width: max-content;

  div {
    border: 0;
    outline: 0;
    width: max-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    height: max-content;
    padding: 2px 16px;
    direction: ltr;
    &:hover {
      cursor: pointer;
    }
    &.success {
      background: $success-badge;
      color: $success-badge-text;
    }
    &.primary {
      background: $primary;
      color: $white;
    }
    &.secondary {
      background: $secondary;
      color: $white;
    }
    &.error {
      background: $danger-badge;
      color: $danger;
    }
    &.waiting {
      background: $waiting-badge;
      color: $white;
    }
    &.info {
      background: $primary;
      color: $white;
    }

    img {
      margin-left: 5px;
    }
  }
}
