@import 'styles/references/index.scss';

.xpay-change-phone {
  .card-body {
    .main {
      &.code {
        > p {
          margin-bottom: 15px !important;
          font-size: 14px;
          color: $bold-span;
          max-width: 90%;
        }
        .input {
          direction: ltr;
          width: auto !important;

          > div {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            > input {
              margin: 0 10px;
              border: 1px solid $table-border;
              border-radius: 10px;
              max-height: 48px;

              @include xpay-pre-md {
                margin: 0 5px;
              }
            }
          }
        }
        .input.has-error {
          input {
            border-color: $danger;
          }
        }

        .input.has-error {
          input {
            border-color: $danger;
          }
        }

        .stats {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: column;
          width: 100%;
          margin: 10px 0;

          span.resend {
            cursor: pointer;
            color: $info;
          }

          .timer {
            justify-self: flex-start;
            display: flex;
            justify-content: center;
            align-items: center;

            > div {
              left: auto !important;
              top: auto !important;
              > svg {
                display: none;
              }
              > div {
                position: relative !important;
                height: 30px !important;
                width: 30px !important;
              }
            }
          }
          .errors {
            display: flex;
            justify-self: flex-start;
            justify-content: flex-start;
            align-items: center;
            column-gap: 4px;
            color: $danger;
            font-size: 12px;
            min-width: 50px;
          }
          > span {
            min-width: 50px;
          }
        }

        .timer {
          justify-self: center;
          display: flex;
          justify-content: center;
          align-items: center;

          > div {
            left: auto !important;
            top: auto !important;
            > svg {
              display: none;
            }
            > div {
              position: relative !important;
              height: 30px !important;
              width: 30px !important;
            }
          }
        }
        .change-number {
          margin-top: 10px;
          color: $info;
          cursor: pointer;
        }
      }
    }
  }
}
