@import "src/styles/references/index.scss";

.xpay-markets-dashboard {
  width: 100% !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  column-gap: 5px !important;
  border-radius: 20px !important;
  padding: 24px !important;
  height: auto !important;

  @include xpay-pre-lg {
    width: 100%;
    background: $bg;
    padding: 0 24px !important;
  }
  @include xpay-pre-md {
    width: 100%;
    padding: 0;
    margin: 0 auto;
  }

  .desktop-show {
    display: flex;
    width: 100%;
    display: flex;
    width: 100%;
    padding-bottom: 24px;

    @include xpay-pre-lg {
      display: none;
    }
  }

  .mobile-show {
    display: none;
    height: 100%;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-bottom: 42px;

    > .xpay-input {
      margin-bottom: 16px;
      width: 100%;
      max-width: 425px;

      .input-field {
        border-radius: 5px;
        background: $white;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .anticon {
          margin: 12px 14px;
          svg {
            path {
              fill: $primary;
            }
          }
        }
      }
    }
    > .xpay-dropdown {
      margin-bottom: 16px;
      width: 100%;
      max-width: 425px;
      border-radius: 5px;

      .dropdown-selector {
        width: 100%;
        justify-content: space-between;
        padding: 14px 12px;
      }

      .dropdown-items {
        max-width: unset;
      }
    }

    > .coin-card {
      margin-bottom: 24px;
      background: $white;
      width: 100%;
      padding: 16px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      border: 1px solid $table-border;
      border-radius: 20px;
      max-width: 425px;
      height: 250px;

      .card-body {
        width: 100%;
        margin: 0;
        padding: 0;
        > div {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 16px;
        }

        > div:nth-child(1),
        > div:nth-child(2),
        > div:nth-child(3) {
          .left {
            font-weight: 500;
          }
        }

        > div:nth-child(4) {
          .right {
            > p {
              margin-left: 4px;
              span {
                margin-left: 4px;
                font-family: "Montserrat";
                font-size: 10px;
              }
            }
            > span {
              margin-left: 4px;
              text-align: left;
              direction: ltr;
              &.success {
                color: $success;
              }
              &.danger {
                color: $danger;
              }
            }
          }
        }

        &:first-child {
          .right {
            justify-content: flex-start;
            align-items: center;

            img,
            p,
            span {
              margin-left: 8px;
            }

            img {
              width: 25px;
              height: 25px;
            }

            p {
              font-size: 12px;
              font-weight: 500;
            }

            span {
              color: $gray-text;
              font-family: "Montserrat";
              font-size: 12px;
            }
          }

          .left {
            justify-content: flex-start;
            align-items: center;

            p {
              font-family: "Montserrat" !important;
              font-size: 12px;
            }
          }
        }

        &:nth-child(4) {
          .right {
            p {
              span {
                margin-right: 4px;
                margin-left: 4px;
                font-size: 10px;
                font-family: "Montserrat";
              }
            }
            > span {
              &.success {
                color: $success;
              }
              &.danger {
                color: $danger;
              }
            }
          }
        }

        .right,
        .left {
          display: flex;
          width: max-content;
        }
      }

      .xpay-button {
        max-width: unset;
        margin: 0;
        width: 1005;
      }
    }

    @include xpay-pre-lg {
      display: flex;
    }
  }

  ::-webkit-scrollbar {
    width: 0px;
  }

  ::-webkit-scrollbar-thumb {
    background: $scroll-thumb-bg;
  }

  ::-webkit-scrollbar-track {
    background: $white;
  }
  .price-chart-table {
    width: 100%;
    margin: 0 auto;
    border: 1px solid $table-border;
    border-radius: 10px;

    div[role="rowgroup"]:first-child {
      div[role="row"] {
        background: rgba(217, 223, 255, 0.2);
      }
    }

    div[role="row"] {
      height: 69px;
      border-bottom: 1px solid $table-border !important;

      &:hover {
        background: rgba(217, 223, 255, 0.2);
      }
      div[role="gridcell"]:nth-child(4) {
        padding: 0px !important;

        .recharts-surface {
          width: 120px !important;
        }
      }
    }

    .rdt_Pagination {
      display: none;
    }
    div[role="gridcell"] {
      height: 100%;
      min-width: max-content !important;

      .xpay-button {
        width: 107px;
        border-radius: 5px;
        button {
          height: 35px;
          width: max-content;
        }
      }
    }
    div[data-column-id="last_usdt_price"] {
      @include xpay-pre-lg {
        justify-content: end !important;
      }
    }
    div[data-column-id="segmented"] {
      justify-content: center;
    }
    div[role="columnheader"] div {
      color: $gray-text;
    }

    .xpay-badge {
      width: max-content;
      border-radius: 10px;
    }
    img {
      height: 32px !important;
      margin-left: 6px;
    }
    p.market {
      font-weight: 500;
      display: flex;
      align-items: center;
      width: max-content;
      span.symbol {
        font-family: monospace !important;
        font-size: 11px;
        margin-right: 4px;
        opacity: 0.6;
      }
    }
  }
}
